import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import { addToCart, removeFromCart } from '../actions/cartActions'
import Meta from '../components/Meta'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'
import { currencies } from '../i18n'

const CartScreen = ({ match, location, history }) => {
  const productId = match.params.id
  const { t } = useTranslation();

  const qty = location.search ? Number(location.search.split("?")[1].split("&")[0].split("=")[1]) : 1
  var discount = location.search ? location.search.split("?")[1].split("&")[1].split("=")[1] : 0

  // const addon = location.search ? decodeURIComponent(location.search.split("?")[1].split("&")[1].split("=")[1]) : "no addon"
  // const addonPrice = location.search ? decodeURIComponent(location.search.split("?")[1].split("&")[2].split("=")[1]) : 0

  const dispatch = useDispatch()

  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  useEffect(() => {
    if (productId) {

    }
  }, [dispatch, productId, qty])

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id))
  }

  const checkoutHandler = () => {
    history.push('/shipping')
  }

  const currencInfo = localStorage.getItem("Currencies_difatours") || 'DHS';

  const getTotalCarte = (cartItems) => {
    var total = 0;
    if (currencInfo === "DHS") {
      for (let index = 0; index < cartItems.length; index++) {
        const element = cartItems[index];
        if(element.itemProd.length!==0){
          if (element.fqte !== 0) {
            console.log("first");
            total += (parseFloat(getItemSelectPriceCard(element.items, element.itemProd)) - (parseFloat(getItemSelectPriceCard(element.items, element.itemProd)) * 1)) * element.fqte;
          }
          if (element.twqte !== 0) {
            console.log("two");
            total += (parseFloat(getItemSelectPriceCard(element.items, element.itemProd)) - (parseFloat(getItemSelectPriceCard(element.items, element.itemProd)) * 0.5)) * element.twqte;
          }
          if (element.thqte !== 0) {
            console.log("three");
            total += parseFloat(getItemSelectPriceCard(element.items, element.itemProd)) * element.thqte;
          }

        }else{
          if (element.fqte !== 0) {
            console.log("first");
            total += (parseFloat(element.price) - (parseFloat(element.price) * 1)) * element.fqte;
          }
          if (element.twqte !== 0) {
            console.log("two");
            total += (parseFloat(element.price) - (parseFloat(element.price) * 0.5)) * element.twqte;
          }
          if (element.thqte !== 0) {
            console.log("three");
            total += parseFloat(element.price) * element.thqte;
          }
        }
      }
    } else {
      for (let index = 0; index < cartItems.length; index++) {
        const element = cartItems[index];
        if(element.itemProd.length!==0){
          if (element.fqte !== 0) {
            console.log("first");
            total += (parseFloat(getItemSelectPriceCard(element.items, element.itemProd)) - (parseFloat(getItemSelectPriceCard(element.items, element.itemProd)) * 1)) * element.fqte;
          }
          if (element.twqte !== 0) {
            console.log("two");
            total += (parseFloat(getItemSelectPriceCard(element.items, element.itemProd)) - (parseFloat(getItemSelectPriceCard(element.items, element.itemProd)) * 0.5)) * element.twqte;
          }
          if (element.thqte !== 0) {
            console.log("three");
            total += parseFloat(getItemSelectPriceCard(element.items, element.itemProd)) * element.thqte;
          }

        }else{

          if (element.fqte !== 0) {
            console.log("first");
            total += (parseFloat(element.priceEuro) - (parseFloat(element.priceEuro) * 1)) * element.fqte;
          }
          if (element.twqte !== 0) {
            console.log("two");
            total += (parseFloat(element.priceEuro) - (parseFloat(element.priceEuro) * 0.5)) * element.twqte;
          }
          if (element.thqte !== 0) {
            console.log("three");
            total += parseFloat(element.priceEuro) * element.thqte;
          }
        }
      }
    }


    return total.toFixed(2);
  }


  const getItemSelectCard =(items, itemProd)=>{

    var listItems = [];
    for (let index = 0; index < itemProd.length; index++) {
      const element = itemProd[index];
      for (let indexAll = 0; indexAll < items.length; indexAll++) {
        const elementAll = items[indexAll];
        if(elementAll._id===element){
          listItems.push(elementAll);
          break;
        }
      }
    }

    return listItems;
  }

  const getItemSelectPriceCard =(items, itemProd)=>{

    var total = 0;
    for (let index = 0; index < getItemSelectCard(items, itemProd).length; index++) {
      const element = getItemSelectCard(items, itemProd)[index];
      if(currencInfo === "DHS"){
        total+=parseFloat(element.price);
      }else{
        total+=parseFloat(element.price_euro);
      }
    }

    return parseFloat(total);
  }



  return (
    <div className="pt-32 mt-16 ">

      <div className="max-w-screen-lg mx-auto  justify-between">
        <div className="flex-1 px-4">
          <div className="flex items-center ">

            <h1 className="px-3 text-2xl">{t("shopping_cart")} </h1>

          </div>
          {cartItems.length === 0 ? (
            <div className='py-10 text-center'>
              <div>
                {t("cart_empty")}
              </div>
              <div className='pt-4'>
                <Link className='text-blue-600' to={"/"} >{t("go_back")}</Link>
              </div>
            </div>
          ) : (
            <div className="py-2 " dir='ltr' >
              {cartItems.map((item) => (
                <div key={item.product}>
                  <div className="flex my-2 items-center px-5 py-3 justify-between w-full  bg-white shadow-sm rounded-md">
                    <div className="flex ">
                      <img src={`${process.env.REACT_APP_ENV == "prod" ? 'https://api.diffatours.com' : 'http://127.0.0.1:8080'}${item.image}`} alt={item.name} className="w-20 h-20" />
                      <div className="pl-4" >
                        <div className="text-opacity-90 text-xs "> {item.dateBook}</div>
                        <Link className="text-lg " to={`/product/${item.product}`}>{item.name}</Link>
                        <div className="text-lg font-bold opacity-90 " >{parseFloat(item.items.length!==0?getItemSelectPriceCard(item.items, item.itemProd): currencInfo === "DHS" ? item.price : item.priceEuro).toFixed(2)} {t(currencies.find(l => l.code === currencInfo).price)}</div>
                        {
                          item.items.length!==0?
                          <div>
                            <div className='text-sm'>{t("you_select_these_items")}:</div>
                            {
                              getItemSelectCard(item.items,item.itemProd).map((itemProd)=>
                              <>
                              <div className='text-xs font-bold'> - {
                                Cookies.get("i18next") === "ar" && itemProd.namear ?
                                itemProd.namear
                                  : Cookies.get("i18next") === "fr" && itemProd.namefr ?
                                  itemProd.namefr
                                    : Cookies.get("i18next") === "it" && itemProd.nameit ?
                                    itemProd.nameit
                                      : Cookies.get("i18next") === "nl" && itemProd.namenl ?
                                      itemProd.namenl
                                        : itemProd.name
                              } ({parseFloat(currencInfo === "DHS" ? itemProd.price : itemProd.price_euro).toFixed(2)} {t(currencies.find(l => l.code === currencInfo).price)})</div>
                              </>)
                            }
                          </div>:null
                        }

                        {
                          item.fqte !== 0 ?
                            <div className={Cookies.get("i18next") === "ar" ? 'flex justify-end items-center mb-2' : 'flex items-center mb-2'} dir={Cookies.get("i18next") === "ar" ? "rtl" : ""}>
                              <div className="text-sm font-bold opacity-70" >{t("0_to_4_years")} ({Number(item.fdiscount) * 100}%) :  ( { item.items.length!==0?parseFloat(getItemSelectPriceCard(item.items, item.itemProd)-getItemSelectPriceCard(item.items, item.itemProd) * Number(item.fdiscount)).toFixed(2): currencInfo === "DHS" ? parseFloat(item.price - item.price * Number(item.fdiscount)).toFixed(2) : parseFloat(item.priceEuro - item.priceEuro * Number(item.fdiscount)).toFixed(2)} {t(currencies.find(l => l.code === currencInfo).price)})</div>
                              <div className="border p-1 mx-3" >
                                <select
                                  dir='ltr'
                                  className="outline-none w-10 bg-transparent select "
                                  value={item.fqte}
                                  onChange={(e) => {
                                    dispatch(
                                      addToCart(item.product, Number(e.target.value), item.fdiscount, item.twqte, item.twdiscount, item.thqte, item.thdiscount,item.dateBook, item.itemProd))
                                  }
                                  }
                                >
                                  {[...Array(item.countInStock).keys()].map((x) => (
                                    <option key={x + 1} value={x + 1}>
                                      {x + 1}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            : null
                        }
                        {
                          item.twqte !== 0 ?
                            <div className={Cookies.get("i18next") === "ar" ? 'flex justify-end items-center mb-2' : 'flex items-center mb-2'} dir={Cookies.get("i18next") === "ar" ? "rtl" : ""}>
                              <div className="text-sm font-bold opacity-70" >{t("4_to_12_years")} ({Number(item.twdiscount) * 100}%) :  ( {item.items.length!==0?parseFloat(getItemSelectPriceCard(item.items, item.itemProd)-getItemSelectPriceCard(item.items, item.itemProd) * Number(item.twdiscount)).toFixed(2):currencInfo === "DHS" ? parseFloat(item.price - item.price * Number(item.twdiscount)).toFixed(2) : parseFloat(item.priceEuro - item.priceEuro * Number(item.twdiscount)).toFixed(2)} {t(currencies.find(l => l.code === currencInfo).price)})</div>
                              <div className="border p-1 mx-3" >
                                <select
                                  dir='ltr'
                                  className="outline-none w-10 bg-transparent select "
                                  value={item.twqte}
                                  onChange={(e) => {

                                    dispatch(
                                      addToCart(item.product, item.fqte, item.fdiscount, Number(e.target.value), item.twdiscount, item.thqte, item.thdiscount,item.dateBook, item.itemProd))
                                  }
                                  }
                                >
                                  {[...Array(item.countInStock).keys()].map((x) => (
                                    <option key={x + 1} value={x + 1}>
                                      {x + 1}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            : null
                        }
                        {
                          item.thqte !== 0 ?
                            <div className={Cookies.get("i18next") === "ar" ? 'flex justify-end items-center mb-2' : 'flex items-center mb-2'} dir={Cookies.get("i18next") === "ar" ? "rtl" : ""}>
                              <div className="text-sm font-bold opacity-70"  >{t("adult")} ({Number(item.thdiscount) * 100}%) :  ( {item.items.length!==0?parseFloat(getItemSelectPriceCard(item.items, item.itemProd)-getItemSelectPriceCard(item.items, item.itemProd) * Number(item.thdiscount)).toFixed(2):currencInfo === "DHS" ? parseFloat(item.price - item.price * Number(item.thdiscount)).toFixed(2) : parseFloat(item.priceEuro - item.priceEuro * Number(item.thdiscount)).toFixed(2)} {t(currencies.find(l => l.code === currencInfo).price)}) </div>
                              <div className="border p-1 mx-3" >
                                <select
                                  className="outline-none w-10 bg-transparent select "
                                  dir='ltr'
                                  value={item.thqte}
                                  onChange={(e) => {
                                    console.log(item.itemProd);
                                    dispatch(
                                      addToCart(item.product, item.fqte, item.fdiscount, item.twqte, item.twdiscount, Number(e.target.value), item.thdiscount,item.dateBook, item.itemProd))
                                  }

                                  }
                                >
                                  {[...Array(item.countInStock).keys()].map((x) => (
                                    <option key={x + 1} value={x + 1}>
                                      {x + 1}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            : null
                        }
                      </div>
                    </div>


                    <div >
                      <button
                        type='button'
                        variant='light'
                        onClick={() => removeFromCartHandler(item.product)}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="px-4 flex justify-end">
          <div >
            <div className="">
              <h2 className="py-3">
                {t("subtotal_items", { items: cartItems.length, total: getTotalCarte(cartItems), currency: t(currencies.find(l => l.code === currencInfo).price) })}

                {/* Subtotal ({cartItems.length}) items
                : <span className="font-bold">

                  {getTotalCarte(cartItems)}
                </span> */}
              </h2>
            </div>
            <div className='w-full'>
              <button
                type='button'
                className='p-2 border border-primary text-primary mr-4 '

                onClick={() =>
                  history.go(-2)}
              >
                {t("continue_shopping")}
              </button>
              <button
                type='button'
                className={`p-2 border border-primary bg-primary disabled:bg-opacity-40 text-white `}
                disabled={cartItems.length === 0}
                onClick={checkoutHandler}
              >
                {t("proceed_to_checkout")}
              </button>
            </div>

          </div>

        </div>
      </div>
    </div>
  )
}

export default CartScreen
