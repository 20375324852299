import React, { useState, useEffect } from "react";
import axios from "axios";
import { PayPalButton } from "react-paypal-button-v2";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import {
  getOrderDetails,
  payOrder,
  deliverOrder,
} from "../actions/orderActions";
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
} from "../constants/orderConstants";
import PaymentForm from "../components/PaymentForm";
import { addProductReview } from "../actions/productActions";
import { useTranslation } from "react-i18next";
import { currencies } from "../i18n";
import { t } from "i18next";
import Cookies from "js-cookie";

const OrderScreen = ({ match, history }) => {
  const orderId = match.params.id;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;

  const orderPay = useSelector((state) => state.orderPay);
  const { loading: loadingPay, success: successPay } = orderPay;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // const currencInfo = localStorage.getItem("Currencies_difatours") || 'DHS';

  if (!loading) {
    //   Calculate prices
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2);
    };
    order.itemsPrice = addDecimals(
      order.orderItems.reduce((acc, item) => acc + item.price, 0)
    );
  }
  const [showDiag, setShowDiag] = useState(false);
  const [idProd, setIdProd] = useState("");
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(0);

  const showDiagRev = (id) => {
    setShowDiag(true);
    setIdProd(id);
    setRating(0);
    setReview("");
  };

  const hideDiagRev = () => {
    setShowDiag(false);
    setIdProd("");
    setRating(0);
    setReview("");
  };
  const handleRatingChange = (ratingValue) => {
    setRating(ratingValue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here, you can save the review and rating data to your backend or do further processing
    dispatch(
      addProductReview(
        match.params.id,
        idProd,
        rating,
        review,
        order.shippingAddress?.firstName + " " + order.shippingAddress?.lastName
      )
    ).then(() => {
      //   dispatch(listProductDetails(match.params.id))
      hideDiagRev();
      window.location.reload();
    });
    // Clear the input fields after submitting
  };
  const handleInputChange = (e) => {
    setReview(e.target.value);
  };

  useEffect(() => {
    if (!order || successPay || successDeliver || order._id !== orderId) {
      dispatch({ type: ORDER_PAY_RESET });
      dispatch({ type: ORDER_DELIVER_RESET });
      dispatch(getOrderDetails(orderId));
    } else if (!order.isPaid) {
      // proceed payment btn hide or show
    }
  }, [dispatch, orderId, successPay, successDeliver, order]);

  const successPaymentHandler = (paymentResult) => {
    dispatch(payOrder(orderId, paymentResult));
  };
  console.log(order);
  const deliverHandler = () => {
    dispatch(deliverOrder(order));
  };

  const getTotalItem = (item, currency_info) => {
    var total = 0;
    if (currency_info === "DHS") {
      if (item.items.length !== 0) {
        if (item.fqte !== 0) {
          console.log("first");
          total +=
            (parseFloat(getItemSelectPriceCard(item.items, currency_info)) -
              parseFloat(getItemSelectPriceCard(item.items, currency_info)) *
                1) *
            item.fqte;
        }
        if (item.twqte !== 0) {
          console.log("two");
          total +=
            (parseFloat(getItemSelectPriceCard(item.items, currency_info)) -
              parseFloat(getItemSelectPriceCard(item.items, currency_info)) *
                0.5) *
            item.twqte;
        }
        if (item.thqte !== 0) {
          console.log("three");
          total +=
            parseFloat(getItemSelectPriceCard(item.items, currency_info)) *
            item.thqte;
        }
      } else {
        if (item.fqte !== 0) {
          console.log("first");
          total +=
            (parseFloat(item.price) - parseFloat(item.price) * 1) * item.fqte;
        }
        if (item.twqte !== 0) {
          console.log("two");
          total +=
            (parseFloat(item.price) - parseFloat(item.price) * 0.5) *
            item.twqte;
        }
        if (item.thqte !== 0) {
          console.log("three");
          total += parseFloat(item.price) * item.thqte;
        }
      }
    } else {
      if (item.items.length !== 0) {
        if (item.fqte !== 0) {
          console.log("first");
          total +=
            (parseFloat(getItemSelectPriceCard(item.items, currency_info)) -
              parseFloat(getItemSelectPriceCard(item.items, currency_info)) *
                1) *
            item.fqte;
        }
        if (item.twqte !== 0) {
          console.log("two");
          total +=
            (parseFloat(getItemSelectPriceCard(item.items, currency_info)) -
              parseFloat(getItemSelectPriceCard(item.items, currency_info)) *
                0.5) *
            item.twqte;
        }
        if (item.thqte !== 0) {
          console.log("three");
          total +=
            parseFloat(getItemSelectPriceCard(item.items, currency_info)) *
            item.thqte;
        }
      } else {
        if (item.fqte !== 0) {
          console.log("first");
          total +=
            (parseFloat(item.price_euro) - parseFloat(item.price_euro) * 1) *
            item.fqte;
        }
        if (item.twqte !== 0) {
          console.log("two");
          total +=
            (parseFloat(item.price_euro) - parseFloat(item.price_euro) * 0.5) *
            item.twqte;
        }
        if (item.thqte !== 0) {
          console.log("three");
          total += parseFloat(item.price_euro) * item.thqte;
        }
      }
    }

    return total.toFixed(2);
  };

  // const [hash, setHash] = useState("")

  // const prePayment = (e) => {
  //   e.preventDefault()
  //   console.log("before")
  //   axios.post('/api/payment/prepare',dataForm).then(res=> {
  //     console.log(res.data)
  //     setHash(res.data)

  //   }).catch(err=> {
  //     console.log(err)
  //   })
  // }

  const checkIsReview = (product, reviews) => {
    var check = true;
    for (let index = 0; index < reviews.length; index++) {
      const element = reviews[index];

      if (element.product === product) {
        check = false;
        break;
      }
    }

    return check;
  };

  const getItemSelectCard = (items) => {
    var listItems = [];
    for (let indexAll = 0; indexAll < items.length; indexAll++) {
      const elementAll = items[indexAll];
      listItems.push(elementAll);
    }

    return listItems;
  };

  const getItemSelectPriceCard = (items, currencInfo) => {
    var total = 0;
    for (let index = 0; index < getItemSelectCard(items).length; index++) {
      const element = getItemSelectCard(items)[index];
      if (currencInfo === "DHS") {
        total += parseFloat(element.price);
      } else {
        total += parseFloat(element.price_euro);
      }
    }
    return parseFloat(total);
  };

  const getTotalItemSlected = (item, currencInfo) => {
    var total = 0;
    if (currencInfo === "DHS") {
      if (item.items.length !== 0) {
        if (item.fqte !== 0) {
          console.log("first");
          total +=
            (parseFloat(getItemSelectPriceCard(item.items, currencInfo)) -
              parseFloat(getItemSelectPriceCard(item.items, currencInfo)) * 1) *
            item.fqte;
        }
        if (item.twqte !== 0) {
          console.log("two");
          total +=
            (parseFloat(getItemSelectPriceCard(item.items, currencInfo)) -
              parseFloat(getItemSelectPriceCard(item.items, currencInfo)) *
                0.5) *
            item.twqte;
        }
        if (item.thqte !== 0) {
          console.log("three");
          total +=
            parseFloat(getItemSelectPriceCard(item.items, currencInfo)) *
            item.thqte;
        }
      } else {
        if (item.fqte !== 0) {
          console.log("first");
          total +=
            (parseFloat(item.price) - parseFloat(item.price) * 1) * item.fqte;
        }
        if (item.twqte !== 0) {
          console.log("two");
          total +=
            (parseFloat(item.price) - parseFloat(item.price) * 0.5) *
            item.twqte;
        }
        if (item.thqte !== 0) {
          console.log("three");
          total += parseFloat(item.priceEuro) * item.thqte;
        }
      }
    } else {
      if (item.items.length !== 0) {
        if (item.fqte !== 0) {
          console.log("first");
          total +=
            (parseFloat(getItemSelectPriceCard(item.items, currencInfo)) -
              parseFloat(getItemSelectPriceCard(item.items, currencInfo)) * 1) *
            item.fqte;
        }
        if (item.twqte !== 0) {
          console.log("two");
          total +=
            (parseFloat(getItemSelectPriceCard(item.items, currencInfo)) -
              parseFloat(getItemSelectPriceCard(item.items, currencInfo)) *
                0.5) *
            item.twqte;
        }
        if (item.thqte !== 0) {
          console.log("three");
          total +=
            parseFloat(getItemSelectPriceCard(item.items, currencInfo)) *
            item.thqte;
        }
      } else {
        if (item.fqte !== 0) {
          console.log("first");
          total +=
            (parseFloat(item.priceEuro) - parseFloat(item.priceEuro) * 1) *
            item.fqte;
        }
        if (item.twqte !== 0) {
          console.log("two");
          total +=
            (parseFloat(item.priceEuro) - parseFloat(item.priceEuro) * 0.5) *
            item.twqte;
        }
        if (item.thqte !== 0) {
          console.log("three");
          total += parseFloat(item.priceEuro) * item.thqte;
        }
      }
    }

    return total.toFixed(2);
  };

  const changeDateFormat = (date) => {
    const datetime = new Date(date);

    // Get the date components
    const year = datetime.getFullYear();
    const month = ("0" + (datetime.getMonth() + 1)).slice(-2);
    const day = ("0" + datetime.getDate()).slice(-2);

    // Get the time components
    const hours = ("0" + datetime.getHours()).slice(-2);
    const minutes = ("0" + datetime.getMinutes()).slice(-2);

    // Construct the formatted datetime string
    const formattedDatetime = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDatetime;
  };

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <div className="container  max-w-screen-lg mx-auto pt-32 mt-16 ">
      <h1 className=" px-2 text-xl pb-3 font-bold">
        {t("order_number", { order: order._id })}
      </h1>
      <hr />
      <div className="md:flex pt-2">
        <div className="md:w-2/3 w-full pr-2">
          <div>
            <div className="bg-white m-1 p-2 ">
              <h2 className="pb-2  capitalize font-400 text-xl ">
                {t("personal_information")}
              </h2>
              <hr />
              <p>
                <strong>{t("name")} : </strong>{" "}
                {order.shippingAddress?.firstName}{" "}
                {order.shippingAddress?.lastName}
              </p>
              <p>
                <strong>{t("email")} : </strong>{" "}
                <a href={`mailto:${order.shippingAddress?.email}`}>
                  {order.shippingAddress?.email}
                </a>
              </p>
              <p>
                <strong>{t("phone")} : </strong>{" "}
                <a href={`tel:${order.shippingAddress?.phone}`}>
                  {order.shippingAddress?.phone}
                </a>
              </p>
              <p>
                <strong>{t("passport_or_cin")} : </strong>{" "}
                {order.shippingAddress?.passportCin}
              </p>
              {order.shippingAddress?.type_client === "hotel" ? (
                <>
                  <p className="pb-2">
                    <strong>{t("type")} : </strong> {t("hotel")}
                  </p>

                  <p>
                    <strong>{t("hotel_name")} : </strong>{" "}
                    {order.shippingAddress?.type_name}
                  </p>
                  <p>
                    <strong>{t("city")} : </strong>{" "}
                    {order.shippingAddress?.city}
                  </p>
                  <p>
                    <strong>{t("address")} : </strong>{" "}
                    {order.shippingAddress?.address_client}
                  </p>
                </>
              ) : null}
              {order.shippingAddress?.type_client === "riad" ? (
                <>
                  <p className="pb-2">
                    <strong>{t("type")} : </strong> {t("riad")}
                  </p>
                  <p>
                    <strong>{t("city")} : </strong>{" "}
                    {order.shippingAddress?.city}
                  </p>
                  <p>
                    <strong>{t("pick_up_point")} :</strong>{" "}
                    {order.shippingAddress?.address_client}
                  </p>
                </>
              ) : null}
            </div>

            <div className="bg-white m-1 p-2 ">
              <h2 className="text-xl font-400 ">{t("payment_method")}</h2>
              <hr />
              <p className="pt-2">
                <strong>{t("method")} : </strong>
                {order.paymentMethod}
              </p>
              {
                order.isPaid ? (
                  <Message variant="success">
                    Paid on {changeDateFormat(order.paidAt)}
                  </Message>
                ) : order.totalPrice === 0 ? (
                  <div className="py-2 text-green-600 font-bold">
                    Free Activity
                  </div>
                ) : (
                  <></>
                )
                // (
                //   <Message variant='danger'>Not Paid</Message>
                // )
              }
            </div>

            <div className="bg-white m-1 p-2 ">
              <h2 className="py-2 border-b font-600 text-xl">
                {t("order_items")}
              </h2>
              {order.orderItems.length === 0 ? (
                <Message>{t("order_items_empty")}</Message>
              ) : (
                <div dir="ltr">
                  {order.orderItems.map((item, index) => (
                    <div key={index}>
                      <div className="flex py-2 items-center border-b justify-between">
                        <div className="flex">
                          <img
                            className="h-16 w-16"
                            src={`${
                              process.env.REACT_APP_ENV === "prod"
                                ? "https://api.diffatours.com"
                                : "http://127.0.0.1:8080"
                            }${item.image}`}
                            alt={item.name}
                          />
                          <div className="pl-4">
                            <div className="flex flex-row">
                              <div className="text-opacity-90 text-xs flex  items-center justify-center content-center text-center">
                                {" "}
                                {item.date}
                              </div>
                              {order.isPaid &&
                              checkIsReview(item.product, order.reviews) ? (
                                <div className="text-opacity-90 text-xs mx-2 items-center justify-center content-center text-center">
                                  <button
                                    onClick={() => showDiagRev(item.product)}
                                    className="rounded-full bg-gray-400 text-white text-xs font-bold px-2 py-1"
                                  >
                                    {t("write_review")}
                                  </button>
                                </div>
                              ) : null}
                            </div>
                            <Link to={`/product/${item.product}`}>
                              {item.name}
                            </Link>

                            <div className="font-bold">
                              {parseFloat(
                                item.items.length !== 0
                                  ? getItemSelectPriceCard(
                                      item.items,
                                      order.currency_info
                                    )
                                  : order.currency_info === "DHS"
                                  ? item.price
                                  : item.price_euro
                              ).toFixed(2)}{" "}
                              {t(
                                currencies.find(
                                  (l) => l.code === order.currency_info
                                ).price
                              )}
                            </div>
                            {item.items.length !== 0 ? (
                              <>
                                <div className="text-sm">
                                  {t("you_select_these_items")}:
                                </div>
                                {getItemSelectCard(item.items).map(
                                  (itemProd) => (
                                    <>
                                      <div className="text-xs font-bold">
                                        {" "}
                                        -{" "}
                                        {Cookies.get("i18next") === "ar" &&
                                        itemProd.namear
                                          ? itemProd.namear
                                          : Cookies.get("i18next") === "fr" &&
                                            itemProd.namefr
                                          ? itemProd.namefr
                                          : Cookies.get("i18next") === "it" &&
                                            itemProd.nameit
                                          ? itemProd.nameit
                                          : Cookies.get("i18next") === "nl" &&
                                            itemProd.namenl
                                          ? itemProd.namenl
                                          : itemProd.name}{" "}
                                        (
                                        {parseFloat(
                                          order.currency_info === "DHS"
                                            ? itemProd.price
                                            : itemProd.price_euro
                                        ).toFixed(2)}{" "}
                                        {t(
                                          currencies.find(
                                            (l) =>
                                              l.code === order.currency_info
                                          ).price
                                        )}
                                        )
                                      </div>
                                    </>
                                  )
                                )}
                              </>
                            ) : null}

                            <div className="pt-1  opacity-60 text-sm ">
                              {item.fqte !== 0 ? (
                                <div className="text-xs font-bold opacity-70">
                                  {t("0_to_4_years")} : ({item.fqte} *{" "}
                                  {item.items.length !== 0
                                    ? parseFloat(
                                        getItemSelectPriceCard(
                                          item.items,
                                          order.currency_info
                                        ) -
                                          getItemSelectPriceCard(
                                            item.items,
                                            order.currency_info
                                          ) *
                                            Number(item.fdiscount)
                                      )
                                    : order.currency_info === "DHS"
                                    ? (
                                        item.price -
                                        item.price * Number(item.fdiscount)
                                      ).toFixed(2)
                                    : (
                                        item.price_euro -
                                        item.price_euro * Number(item.fdiscount)
                                      ).toFixed(2)}
                                  {t(
                                    currencies.find(
                                      (l) => l.code === order.currency_info
                                    ).price
                                  )}
                                  )
                                </div>
                              ) : null}
                              {item.twqte !== 0 ? (
                                <div className="text-xs font-bold opacity-70">
                                  {t("4_to_12_years")} : ({item.twqte} *{" "}
                                  {item.items.length !== 0
                                    ? parseFloat(
                                        getItemSelectPriceCard(
                                          item.items,
                                          order.currency_info
                                        ) -
                                          getItemSelectPriceCard(
                                            item.items,
                                            order.currency_info
                                          ) *
                                            Number(item.twdiscount)
                                      )
                                    : order.currency_info === "DHS"
                                    ? (
                                        item.price -
                                        item.price * Number(item.twdiscount)
                                      ).toFixed(2)
                                    : (
                                        item.price_euro -
                                        item.price_euro *
                                          Number(item.twdiscount)
                                      ).toFixed(2)}
                                  {t(
                                    currencies.find(
                                      (l) => l.code === order.currency_info
                                    ).price
                                  )}
                                  )
                                </div>
                              ) : null}
                              {item.thqte !== 0 ? (
                                <div className="text-xs font-bold opacity-70">
                                  {t("adult")} : ({item.thqte} *{" "}
                                  {item.items.length !== 0
                                    ? parseFloat(
                                        getItemSelectPriceCard(
                                          item.items,
                                          order.currency_info
                                        ) -
                                          getItemSelectPriceCard(
                                            item.items,
                                            order.currency_info
                                          ) *
                                            Number(item.thdiscount)
                                      )
                                    : order.currency_info === "DHS"
                                    ? (
                                        item.price -
                                        item.price * Number(item.thdiscount)
                                      ).toFixed(2)
                                    : (
                                        item.price_euro -
                                        item.price_euro *
                                          Number(item.thdiscount)
                                      ).toFixed(2)}
                                  {t(
                                    currencies.find(
                                      (l) => l.code === order.currency_info
                                    ).price
                                  )}
                                  ){" "}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div></div>
                        <div>
                          {getTotalItem(item, order.currency_info)}
                          {t(
                            currencies.find(
                              (l) => l.code === order.currency_info
                            ).price
                          )}
                          {/* {item.qty} x ({item.price} - {item.price * item.discount} ) MAD  = {item.qty * (parseFloat(item.price) - parseFloat(item.price) * parseFloat(item.discount))} MAD */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="md:w-1/3 w-full pl-2">
          <div className="bg-white p-2">
            <div>
              <h2 className="pb-2  capitalize font-400 text-xl ">
                {t("order_summary")}
              </h2>

              {/* <div className="flex justify-between pt-4">
                  <div className="font-semibold">Items</div>
                  <div>{order.} MAD</div>
              </div> */}
              {/* <div className="flex justify-between pt-2">
                
                  <div className="font-semibold">Shipping</div>
                  <div>${order.shippingPrice}</div>
                
              </div>*/}
              {/* <div className="flex justify-between pt-2">
                <div className="font-semibold">{t("booking_fees")} (5%)</div>
                <div>
                  {order.taxPrice}
                  {t(
                    currencies.find((l) => l.code === order.currency_info).price
                  )}
                </div>
              </div> */}
              <div className="flex justify-between pt-2">
                <div className="font-semibold">{t("total")}</div>
                <div>
                  {order.currency_info == "DHS"
                    ? order.totalPrice
                    : order.totalPriceEuro}
                  {}
                  {t(
                    currencies.find((l) => l.code === order.currency_info).price
                  )}
                </div>
              </div>

              <div className="pt-4">
                {/* <button onClick={payNow} className='p-2 bg-primary text-white w-full'>
                    Pay NOW 
                  </button> */}
                {order.isPaid ? (
                  <div>
                    <div
                      className="bg-green-600 text-center select-none p-2 w-full text-white"
                      // onClick={deliverHandler}
                    >
                      {t("payment_ok")}
                    </div>
                  </div>
                ) : order.totalPrice !== 0 ? (
                  // <PaymentForm title={t("pay_now")} order={order} />
                  <PaymentForm
                    user={userInfo}
                    // disabled={!isChecked}
                    className={"p-2 bg-red-600 text-white w-full"}
                    title={t("pay_now")}
                    order={order}
                  />
                ) : (
                  // <button className='p-2 bg-red-600 text-white w-full' type='submit'>{t("pay_now")}</button>
                  <div
                    className="bg-green-600 text-center select-none p-2 w-full text-white"
                    // onClick={deliverHandler}
                  >
                    {t("order_confirmed")}
                  </div>
                )}
              </div>

              {/* {!order.isPaid && (
                <div className="pt-4">
                  {loadingPay && <Loader />}
                
                  {!sdkReady ? (
                    <Loader />
                  ) : (
                    <PayPalButton
                      amount={order.totalPrice}
                      onSuccess={successPaymentHandler}
                    />
                  )}
                </div>
              )} */}

              {/* {loadingDeliver && <Loader />}
              {userInfo &&
                userInfo.isAdmin &&
                order.isPaid && (
                  <div className="pt-4">
                    <div
                      className='bg-green-600 text-center select-none p-2 w-full text-white'
                      // onClick={deliverHandler}
                    >
                      Payment Ok
                    </div>
                  </div>
                )} */}
            </div>
          </div>
        </div>
      </div>
      {showDiag ? (
        <>
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
            <div className="bg-white p-6 rounded shadow-xl border-2">
              <div className="flex">
                <h3 className="text-lg font-bold mb-4 flex-1">
                  {t("write_review")}
                </h3>
                <button onClick={hideDiagRev} className="p-0 m-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="pt-2">
                <div className="container mx-auto mt-2">
                  <div className="flex items-center">
                    <div>
                      {[1, 2, 3, 4, 5].map((star) => (
                        <span
                          key={star}
                          className={`text-2xl cursor-pointer ${
                            star <= rating ? "text-yellow-400" : "text-gray-300"
                          }`}
                          onMouseEnter={() => handleRatingChange(star)}
                          // onMouseLeave={() => handleRatingChange(0)}
                          onClick={() => handleRatingChange(star)}
                        >
                          ★
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="">
                    <label>{t("review")} : </label>
                    <textarea
                      className="w-full border rounded p-2"
                      rows="2"
                      value={review}
                      onChange={handleInputChange}
                    />
                  </div>
                  <button
                    type="submit"
                    className="bg-blue-300 text-white rounded-full px-3 py-1 w-full"
                    disabled={!rating || !review.trim()}
                    onClick={handleSubmit}
                  >
                    {t("save_review")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default OrderScreen;
