import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import CheckoutSteps from "../components/CheckoutSteps";
import { createOrder } from "../actions/orderActions";
import { ORDER_CREATE_RESET } from "../constants/orderConstants";
import { USER_DETAILS_RESET } from "../constants/userConstants";
import Meta from "../components/Meta";
import { applyCoupon } from "../actions/couponActions";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { currencies } from "../i18n";
import Cookies from "js-cookie";

const PlaceOrderScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [code, setCode] = useState("");
  const { discount } = useSelector((state) => state.coupon);

  const handleApplyCoupon = () => {
    dispatch(applyCoupon(code));
  };
  console.log(discount);

  const cart = useSelector((state) => state.cart);
  // console.log(cart)

  if (
    !cart.shippingAddress.phone &&
    !cart.shippingAddress.email &&
    !cart.shippingAddress.isUnder18 &&
    !cart.shippingAddress.isTermsChecked
  ) {
    history.push("/shipping");
  }
  // else if (!cart.paymentMethod) {
  //   history.push('/payment')
  // }
  //   Calculate prices
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };
  console.log(cart.cartItems);
  cart.itemsPrice = addDecimals(
    cart.cartItems.reduce(
      (acc, item) =>
        acc +
        (parseFloat(item.price) -
          parseFloat(item.price) * parseFloat(item.discount)) *
          item.qty,
      0
    )
  );
  cart.shippingPrice = addDecimals(cart.itemsPrice > 100 ? 0 : 0);
  cart.taxPrice = addDecimals(Number((0.05 * cart.itemsPrice).toFixed(2)));
  cart.totalPrice = (
    Number(cart.itemsPrice) +
    Number(cart.shippingPrice) +
    Number(cart.taxPrice) -
    Number(discount)
  ).toFixed(2);

  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, success, error } = orderCreate;

  useEffect(() => {
    if (success) {
      history.push(`/order/${order._id}`);
      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: ORDER_CREATE_RESET });
    }
    // eslint-disable-next-line
  }, [history, success]);

  const placeOrderHandler = () => {
    dispatch(
      createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        paymentMethod: "CMI",
        itemsPrice: parseFloat(getTotalCarte(cart.cartItems, "DHS")),
        shippingPrice: cart.shippingPrice,
        taxPrice: parseFloat(getTotalCarte(cart.cartItems, "DHS") * 0.05),
        totalPrice: parseFloat(getTotalCarte(cart.cartItems, "DHS")),
        currencyInfo: currencInfo,
        itemsPriceEuro: parseFloat(getTotalCarte(cart.cartItems, "Euro")),
        taxPriceEuro: parseFloat(getTotalCarte(cart.cartItems, "Euro") * 0.05),
        totalPriceEuro: parseFloat(getTotalCarte(cart.cartItems, "Euro")),
        // discount:discount
      })
    );
  };

  const currencInfo = localStorage.getItem("Currencies_difatours") || "DHS";

  const getTotalItem = (item) => {
    var total = 0;
    if (currencInfo === "DHS") {
      if (item.itemProd.length !== 0) {
        if (item.fqte !== 0) {
          console.log("first");
          total +=
            (parseFloat(getItemSelectPriceCard(item.items, item.itemProd)) -
              parseFloat(getItemSelectPriceCard(item.items, item.itemProd)) *
                1) *
            item.fqte;
        }
        if (item.twqte !== 0) {
          console.log("two");
          total +=
            (parseFloat(getItemSelectPriceCard(item.items, item.itemProd)) -
              parseFloat(getItemSelectPriceCard(item.items, item.itemProd)) *
                0.5) *
            item.twqte;
        }
        if (item.thqte !== 0) {
          console.log("three");
          total +=
            parseFloat(getItemSelectPriceCard(item.items, item.itemProd)) *
            item.thqte;
        }
      } else {
        if (item.fqte !== 0) {
          console.log("first");
          total +=
            (parseFloat(item.price) - parseFloat(item.price) * 1) * item.fqte;
        }
        if (item.twqte !== 0) {
          console.log("two");
          total +=
            (parseFloat(item.price) - parseFloat(item.price) * 0.5) *
            item.twqte;
        }
        if (item.thqte !== 0) {
          console.log("three");
          total += parseFloat(item.price) * item.thqte;
        }
      }
    } else {
      if (item.itemProd.length !== 0) {
        if (item.fqte !== 0) {
          console.log("first");
          total +=
            (parseFloat(getItemSelectPriceCard(item.items, item.itemProd)) -
              parseFloat(getItemSelectPriceCard(item.items, item.itemProd)) *
                1) *
            item.fqte;
        }
        if (item.twqte !== 0) {
          console.log("two");
          total +=
            (parseFloat(getItemSelectPriceCard(item.items, item.itemProd)) -
              parseFloat(getItemSelectPriceCard(item.items, item.itemProd)) *
                0.5) *
            item.twqte;
        }
        if (item.thqte !== 0) {
          console.log("three");
          total +=
            parseFloat(getItemSelectPriceCard(item.items, item.itemProd)) *
            item.thqte;
        }
      } else {
        if (item.fqte !== 0) {
          console.log("first");
          total +=
            (parseFloat(item.priceEuro) - parseFloat(item.priceEuro) * 1) *
            item.fqte;
        }
        if (item.twqte !== 0) {
          console.log("two");
          total +=
            (parseFloat(item.priceEuro) - parseFloat(item.priceEuro) * 0.5) *
            item.twqte;
        }
        if (item.thqte !== 0) {
          console.log("three");
          total += parseFloat(item.priceEuro) * item.thqte;
        }
      }
    }

    return total.toFixed(2);
  };

  const getTotalCarte = (cartItems, currencyOrder) => {
    var total = 0;
    if (currencyOrder === "DHS") {
      for (let index = 0; index < cartItems.length; index++) {
        const element = cartItems[index];
        if (element.itemProd.length !== 0) {
          if (element.fqte !== 0) {
            console.log("first");
            total +=
              (parseFloat(
                getItemSelectPriceCard(element.items, element.itemProd)
              ) -
                parseFloat(
                  getItemSelectPriceCard(element.items, element.itemProd)
                ) *
                  1) *
              element.fqte;
          }
          if (element.twqte !== 0) {
            console.log("two");
            total +=
              (parseFloat(
                getItemSelectPriceCard(element.items, element.itemProd)
              ) -
                parseFloat(
                  getItemSelectPriceCard(element.items, element.itemProd)
                ) *
                  0.5) *
              element.twqte;
          }
          if (element.thqte !== 0) {
            console.log("three");
            total +=
              parseFloat(
                getItemSelectPriceCard(element.items, element.itemProd)
              ) * element.thqte;
          }
        } else {
          if (element.fqte !== 0) {
            console.log("first");
            total +=
              (parseFloat(element.price) - parseFloat(element.price) * 1) *
              element.fqte;
          }
          if (element.twqte !== 0) {
            console.log("two");
            total +=
              (parseFloat(element.price) - parseFloat(element.price) * 0.5) *
              element.twqte;
          }
          if (element.thqte !== 0) {
            console.log("three");
            total += parseFloat(element.price) * element.thqte;
          }
        }
      }
    } else {
      for (let index = 0; index < cartItems.length; index++) {
        const element = cartItems[index];
        if (element.itemProd.length !== 0) {
          if (element.fqte !== 0) {
            console.log("first");
            total +=
              (parseFloat(
                getItemSelectPriceCard(element.items, element.itemProd)
              ) -
                parseFloat(
                  getItemSelectPriceCard(element.items, element.itemProd)
                ) *
                  1) *
              element.fqte;
          }
          if (element.twqte !== 0) {
            console.log("two");
            total +=
              (parseFloat(
                getItemSelectPriceCard(element.items, element.itemProd)
              ) -
                parseFloat(
                  getItemSelectPriceCard(element.items, element.itemProd)
                ) *
                  0.5) *
              element.twqte;
          }
          if (element.thqte !== 0) {
            console.log("three");
            total +=
              parseFloat(
                getItemSelectPriceCard(element.items, element.itemProd)
              ) * element.thqte;
          }
        } else {
          if (element.fqte !== 0) {
            console.log("first");
            total +=
              (parseFloat(element.priceEuro) -
                parseFloat(element.priceEuro) * 1) *
              element.fqte;
          }
          if (element.twqte !== 0) {
            console.log("two");
            total +=
              (parseFloat(element.priceEuro) -
                parseFloat(element.priceEuro) * 0.5) *
              element.twqte;
          }
          if (element.thqte !== 0) {
            console.log("three");
            total += parseFloat(element.priceEuro) * element.thqte;
          }
        }
      }
    }
    return total.toFixed(2);
  };

  const { t } = useTranslation();

  const getItemSelectCard = (items, itemProd) => {
    var listItems = [];
    for (let index = 0; index < itemProd.length; index++) {
      const element = itemProd[index];
      for (let indexAll = 0; indexAll < items.length; indexAll++) {
        const elementAll = items[indexAll];
        if (elementAll._id === element) {
          listItems.push(elementAll);
          break;
        }
      }
    }

    return listItems;
  };

  const getItemSelectPriceCard = (items, itemProd) => {
    var total = 0;

    for (
      let index = 0;
      index < getItemSelectCard(items, itemProd).length;
      index++
    ) {
      console.log(currencInfo);

      const element = getItemSelectCard(items, itemProd)[index];
      if (currencInfo === "DHS") {
        total += parseFloat(element.price);
      } else {
        total += parseFloat(element.price_euro);
      }
    }

    return parseFloat(total);
  };

  return (
    <div className="pt-32 container max-w-screen-lg mx-auto mt-16">
      <Meta title={"Place Your Order "} script={""} />
      <CheckoutSteps step2 step4 />
      <div className="md:flex">
        <div className="md:w-2/3 ">
          <div className="pr-1 mt-3">
            <div className="p-2 bg-white my-1">
              <h2 className="text-xl pb-2">{t("information")}</h2>
              <hr />
              <p>
                {/* <strong>Address:</strong> */}
                <p className="pb-2">
                  <strong>{t("name")} : </strong>{" "}
                  {cart.shippingAddress.firstName}{" "}
                  {cart.shippingAddress.lastName}
                </p>
                <p className="pb-2">
                  <strong>{t("email")} : </strong>{" "}
                  <a href={`mailto:${cart.shippingAddress?.email}`}>
                    {cart.shippingAddress?.email}
                  </a>
                </p>
                <p className="pb-2">
                  <strong>{t("phone")} : </strong>{" "}
                  <a href={`tel:${cart.shippingAddress?.phone}`}>
                    {cart.shippingAddress?.phone}
                  </a>
                </p>
                <p className="pb-2">
                  <strong>{t("passport_or_cin")} : </strong>{" "}
                  {cart.shippingAddress?.passportCin}
                </p>
                {cart.shippingAddress?.type_client === "hotel" ? (
                  <>
                    <p className="pb-2">
                      <strong>{t("type")} : </strong> {t("hotel")}
                    </p>

                    <p className="pb-2">
                      <strong>{t("hotel_name")} : </strong>{" "}
                      {cart.shippingAddress?.type_name}
                    </p>
                    <p className="pb-2">
                      <strong>{t("city")} : </strong>{" "}
                      {cart.shippingAddress?.city}
                    </p>
                    <p className="pb-2">
                      <strong>{t("address")} : </strong>{" "}
                      {cart.shippingAddress?.address_client}
                    </p>
                  </>
                ) : null}
                {cart.shippingAddress?.type_client === "riad" ? (
                  <>
                    <p className="pb-2">
                      <strong>{t("type")} : </strong> {t("riad")}
                    </p>
                    <p className="pb-2">
                      <strong>{t("city")} : </strong>{" "}
                      {cart.shippingAddress?.city}
                    </p>
                    <p className="pb-2">
                      <strong>{t("pick_up_point")} : </strong>{" "}
                      {cart.shippingAddress?.address_client}
                    </p>
                  </>
                ) : null}
                {/* <p>
                <strong>ARRIVAL TIME: </strong>{' '}
                {cart.shippingAddress?.arrivalTime}
              </p> */}
              </p>
            </div>

            <div className="p-2 bg-white my-1">
              <h2 className="text-xl pb-2">{t("payment_method")}</h2>
              <hr />
              <strong>{t("method")} : </strong>
              CMI
            </div>

            <div className="p-2 bg-white my-1">
              <h2 className="text-xl pb-2">{t("order_items")}</h2>
              <hr />
              {cart.cartItems.length === 0 ? (
                <Message>{t("cart_empty")}</Message>
              ) : (
                <div dir="ltr">
                  {cart.cartItems.map((item, index) => (
                    <div key={index}>
                      <div className="flex py-2 items-center border-b justify-between">
                        <div className="flex">
                          <img
                            className="h-16 w-16"
                            src={`${
                              process.env.REACT_APP_ENV === "prod"
                                ? "https://api.diffatours.com"
                                : "http://127.0.0.1:8080"
                            }${item.image}`}
                            alt={item.name}
                          />
                          <div className="pl-4">
                            <div className="text-opacity-90 text-xs ">
                              {" "}
                              {item.dateBook}
                            </div>
                            <Link to={`/product/${item.product}`}>
                              {item.name}
                            </Link>
                            <div className="text-lg font-bold opacity-90 ">
                              {parseFloat(
                                item.items.length !== 0
                                  ? getItemSelectPriceCard(
                                      item.items,
                                      item.itemProd
                                    )
                                  : currencInfo === "DHS"
                                  ? item.price
                                  : item.priceEuro
                              ).toFixed(2)}{" "}
                              {t(
                                currencies.find((l) => l.code === currencInfo)
                                  .price
                              )}
                            </div>
                            {item.items.length !== 0 ? (
                              <div>
                                <div className="text-sm">
                                  {t("you_select_these_items")}:
                                </div>
                                {getItemSelectCard(
                                  item.items,
                                  item.itemProd
                                ).map((itemProd) => (
                                  <>
                                    <div className="text-xs font-bold">
                                      {" "}
                                      -{" "}
                                      {Cookies.get("i18next") === "ar" &&
                                      itemProd.namear
                                        ? itemProd.namear
                                        : Cookies.get("i18next") === "fr" &&
                                          itemProd.namefr
                                        ? itemProd.namefr
                                        : Cookies.get("i18next") === "it" &&
                                          itemProd.nameit
                                        ? itemProd.nameit
                                        : Cookies.get("i18next") === "nl" &&
                                          itemProd.namenl
                                        ? itemProd.namenl
                                        : itemProd.name}{" "}
                                      (
                                      {parseFloat(
                                        currencInfo === "DHS"
                                          ? itemProd.price
                                          : itemProd.price_euro
                                      ).toFixed(2)}{" "}
                                      {t(
                                        currencies.find(
                                          (l) => l.code === currencInfo
                                        ).price
                                      )}
                                      )
                                    </div>
                                  </>
                                ))}
                              </div>
                            ) : null}

                            <div className="  opacity-60 text-sm  mt-2">
                              <div className="text-xs">
                                {item.fqte !== 0 ? (
                                  <div className="text-xs font-bold opacity-70">
                                    {t("0_to_4_years")} : ({item.fqte} *{" "}
                                    {item.items.length !== 0
                                      ? parseFloat(
                                          getItemSelectPriceCard(
                                            item.items,
                                            item.itemProd
                                          ) -
                                            getItemSelectPriceCard(
                                              item.items,
                                              item.itemProd
                                            ) *
                                              Number(item.fdiscount)
                                        ).toFixed(2)
                                      : currencInfo === "DHS"
                                      ? (
                                          item.price -
                                          item.price * Number(item.fdiscount)
                                        ).toFixed(2)
                                      : (
                                          item.priceEuro -
                                          item.priceEuro *
                                            Number(item.fdiscount)
                                        ).toFixed(2)}{" "}
                                    {t(
                                      currencies.find(
                                        (l) => l.code === currencInfo
                                      ).price
                                    )}
                                    )
                                  </div>
                                ) : null}
                                {item.twqte !== 0 ? (
                                  <div className="text-xs font-bold opacity-70">
                                    {t("4_to_12_years")} : ({item.twqte} *{" "}
                                    {item.items.length !== 0
                                      ? parseFloat(
                                          getItemSelectPriceCard(
                                            item.items,
                                            item.itemProd
                                          ) -
                                            getItemSelectPriceCard(
                                              item.items,
                                              item.itemProd
                                            ) *
                                              Number(item.twdiscount)
                                        ).toFixed(2)
                                      : currencInfo === "DHS"
                                      ? (
                                          item.price -
                                          item.price * Number(item.twdiscount)
                                        ).toFixed(2)
                                      : (
                                          item.priceEuro -
                                          item.priceEuro *
                                            Number(item.twdiscount)
                                        ).toFixed(2)}{" "}
                                    {t(
                                      currencies.find(
                                        (l) => l.code === currencInfo
                                      ).price
                                    )}
                                    )
                                  </div>
                                ) : null}
                                {item.thqte !== 0 ? (
                                  <div className="text-xs font-bold opacity-70">
                                    {t("adult")} : ({item.thqte} *{" "}
                                    {item.items.length !== 0
                                      ? parseFloat(
                                          getItemSelectPriceCard(
                                            item.items,
                                            item.itemProd
                                          ) -
                                            getItemSelectPriceCard(
                                              item.items,
                                              item.itemProd
                                            ) *
                                              Number(item.thdiscount)
                                        ).toFixed(2)
                                      : currencInfo === "DHS"
                                      ? (
                                          item.price -
                                          item.price * Number(item.thdiscount)
                                        ).toFixed(2)
                                      : (
                                          item.priceEuro -
                                          item.priceEuro *
                                            Number(item.thdiscount)
                                        ).toFixed(2)}
                                    {t(
                                      currencies.find(
                                        (l) => l.code === currencInfo
                                      ).price
                                    )}
                                    ){" "}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div></div>
                        <div>
                          {getTotalItem(item)}{" "}
                          {t(
                            currencies.find((l) => l.code === currencInfo).price
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="md:w-1/3">
          <div className="pl-1 mt-3">
            <div className="bg-white p-2">
              <div>
                <h2 className="text-xl">{t("order_summary")}</h2>
              </div>
              {/* <div className="flex justify-between py-2">
                <div>{t("items")}</div>
                <div>
                  {parseFloat(
                    getTotalCarte(cart.cartItems, currencInfo)
                  ).toFixed(2)}
                  {t(currencies.find((l) => l.code === currencInfo).price)}
                </div>
              </div> */}
              {/* <div className="flex justify-between py-1">
                <div>{t("booking_fees")} (5%)</div>
                <div>
                  {parseFloat(
                    getTotalCarte(cart.cartItems) * 0.05,
                    currencInfo
                  ).toFixed(2)}
                  {t(currencies.find((l) => l.code === currencInfo).price)}
                </div>
              </div> */}

              <div className="flex justify-between py-1">
                <div>{t("total")}</div>
                <div>
                  {parseFloat(
                    getTotalCarte(cart.cartItems, currencInfo)
                  ).toFixed(2)}
                  {t(currencies.find((l) => l.code === currencInfo).price)}
                </div>
              </div>

              {/* {discount > 0 &&
                <div className="flex justify-between py-1">
                  <div>Coupon </div>
                  <div>- {discount / 100 * cart.totalPrice} ({discount}% ) MAD</div>
                </div>
              } */}

              {/* <div className='py-2'>
              
                <div className='flex '>
                  <div className='w-full border rounded-md'>
                    <input type="text" value={code} onChange={(e)=> setCode(e.target.value)} placeholder='Coupon Code ! ' className='p-2 outline-none bg-transparent ' />
                  </div>
                  <button onClick={handleApplyCoupon} className='bg-gray-600 text-white px-4 rounded-r-md'>
                    Apply
                  </button>
                </div>
                
              </div> */}
              <div>
                {error && <Message variant="danger">{t(error)}</Message>}
              </div>
              <div>
                <button
                  className="p-2 bg-primary text-white w-full"
                  type="button"
                  disabled={cart.cartItems === 0}
                  onClick={placeOrderHandler}
                >
                  {t("confirm_your_information")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaceOrderScreen;
