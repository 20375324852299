import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { deleteImage, selectImage } from '../actions/productActions'

const Photo = ({ file }) => {
    return (
        <div className='px-2 '>
            <img src={URL.createObjectURL(file)} className="w-32 h-32 border-2 border-dashed border-gray-400 object-cover rounded-md" alt="" />
        </div>
    )
}

function Photos({ product, productId, setImages }) {
    const [productImages, setProductImages] = useState([]);

    const dispatch = useDispatch()

    const handleDeleteImage = (id) => (ev) => {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer cette image?')) {
            dispatch(deleteImage(productId, id))
        }
    }

    const handleSelectImage = (id) => (ev) => {
        if (window.confirm('Êtes-vous sûr de vouloir sélectionner cette image comme image du produit?')) {
            dispatch(selectImage(id))
        }
    }

    const handleProductFiles = (event) => {
        const data = [];
        for (let i = 0; i < event.target.files.length; i++) {
            data.push(event.target.files[i]);
        }
        setProductImages((old) => [...old, ...data]);
        setImages((old) => [...old, ...data])
    }

    const imageRef = useRef()

    const getImageFirst = (images)=>{
        var check = false;
        var image = null;
        for (let index = 0; index < images.length; index++) {
            const element = images[index];
            if(element.isfirst){
                image=element;
                check=true;
            }
        }
        if(!check){
            image=images[0]
        }
        return image
    }



    return (
        <div className=''>
            <h1 className='text-xl '>Photos </h1>

            <div className='pt-2'>
                <div className='flex flex-wrap  '>

                    {product?.images?.map(item => {
                        return (
                            <div  key={item._id} className=" my-1 relative mx-1 md:mb-0 ">
                                <div className={`absolute cursor-pointer z-50 bottom-3 left-3 w-6 h-6 bg-gray-50 rounded-full ${getImageFirst(product.images)._id===item._id?' opacity-60':'hidden'}`} >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class={`w-6 h-6 `}>
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                    </svg>
                                </div>
                                <div onClick={handleDeleteImage(item._id)} className='absolute cursor-pointer z-50 -top-3 right-0 w-6 h-6 bg-gray-50 rounded-full ' >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                                <img onClick={handleSelectImage(item._id)} className='cursor-pointer w-32 h-32 object-cover rounded-md ' src={`${process.env.REACT_APP_ENV == "prod" ? 'https://api.diffatours.com' : 'http://127.0.0.1:8080'}${item?.image}`} />
                            </div>)
                    }
                    )}
                    <div className='hidden'>
                        <input type="file" accept="image/*" ref={imageRef} className='hidden' onChange={(value)=>{
                            console.log("nice");
                            const selectedFiles = value.target.files;
                            const data = [];
                        
                            for (let i = 0; i < selectedFiles.length; i++) {
                              data.push(selectedFiles[i]);
                            }
                            setProductImages((old) => [...old, ...data]);
                            setImages((old) => [...old, ...data])
                            value.target.value = null;
                        }} multiple />
                    </div>

                    {/* {productImages.map((item, id) => <Photo file={item} key={id} />)} */}
                    {
                        productImages.map((item, index)=>(
                            <div key={index} className='mx-1 my-1 relative'>
                                <img src={URL.createObjectURL(item)} className="w-32 h-32 border-2 border-dashed border-gray-400 object-cover rounded-md" alt="" />
                                <div onClick={()=>{
                                    const updatedFiles = [...productImages];
                                    updatedFiles.splice(index, 1);
                                    setProductImages(updatedFiles);
                                }} className='absolute cursor-pointer z-50 -top-3 right-0 w-6 h-6 bg-gray-50 rounded-full ' >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            </div>
                        ))
                    }

                    <div onClick={() => imageRef.current.click()} className='w-32 h-32  mx-1 my-1 border-2 border-dashed border-blue-400 object-cover rounded-md flex items-center justify-center cursor-pointer bg-gray-50'>
                        <span className='text-gray-400'>  Add Image </span>
                    </div>


                </div>
            </div>


        </div>

    )
}

export default Photos