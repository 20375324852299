import { list } from "postcss";
import React, { useRef, useState } from "react";
import iconInstagram from "../assets/instagram-icon.png";
import iconfacebook from "../assets/facebook-icon.png";
import iconPhone from "../assets/phone-icon.png";
import iconMail from "../assets/mail-icon.png";
import transfOneImage from "../assets/transf-one.jpeg";
import transfTwoImage from "../assets/transf-two.jpeg";
import transfThreeImage from "../assets/transf-three.jpg";

import golfOneImage from "../assets/golf-one.jpeg";
import golfTwoImage from "../assets/golf-two.webp";
import golfThreeImage from "../assets/golf-three.jpeg";

import OneImage from "../assets/one-image.jpg";
import TwoImage from "../assets/two-image.png";
import ThreeImage from "../assets/three.png";
import ForImage from "../assets/for.jpg";
import fiveImage from "../assets/five.jpg";
import { useTranslation } from "react-i18next";

const transfortImage = [transfOneImage, transfTwoImage, transfThreeImage];

const golfImage = [golfOneImage, golfTwoImage, golfThreeImage];

const otherImage = [OneImage, TwoImage, ThreeImage, ForImage, fiveImage];

function OtherServiceScreen() {
  const [activeImageTrans, setActiveImageTrans] = useState(transfortImage[0]);
  const [activeImageGolf, setActiveImageGolf] = useState(golfImage[0]);
  const [activeImageOther, setActiveImageOther] = useState(otherImage[0]);
  const { t } = useTranslation();
  const getImages = (images) => {
    if (images) {
      if (images.length === 1) {
        return images;
      } else {
        var imageslist = [];
        for (let index = 0; index < images.length; index++) {
          const element = images[index];
          // if(element._id!== getImageFirst(images)._id){
          imageslist.push(element);
          // }
        }
        return imageslist;
      }
    } else {
      return [];
    }
  };

  const transforGalleryRef = useRef();
  const golfGalleryRef = useRef();
  const otherGalleryRef = useRef();

  return (
    <div className=" min-h-screen w-screen ">
      <div className="container mx-auto mt-40">
        {/* first  */}
        <h2 className="font-bold flex mb-4 mt-7">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            data-slot="icon"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5"
            />
          </svg>
          {t("Transfers")}
        </h2>
        <div className="flex flex-col-reverse md:flex-row-reverse ">
          {/* Items */}
          <div className="flex-1 md:w-1/2 w-screen my-5">
            <p className="mx-3 my-3">
              {t("a tourism and passenger transport company")}
            </p>
            <div className="flex flex-col ">
              <a
                href="tel:+212524430418"
                className="mx-3 flex justify-start items-center"
              >
                <img src={iconPhone} className="w-8 h-8" alt="diffatours" />
                <div className="hover:text-blue-600 font-bold text-sm mx-2">
                  (+212) 5-24 43 04 18
                </div>
              </a>
              <a
                href="mailto:contact@difatours.com"
                className="flex justify-start items-center mx-3 my-2"
              >
                <img src={iconMail} className="w-8 h-8" alt="diffatours" />
                <div className="hover:text-blue-600 font-bold text-sm mx-2">
                  contact@difatours.com
                </div>
              </a>
              <a
                href="https://www.instagram.com/diffa_tours_maroc/"
                target="_blank"
                className="flex justify-start items-center mx-3 my-2"
              >
                <img
                  src={iconInstagram}
                  className="w-8 h-8"
                  alt="DIFFA TOURS"
                />
                <div className="hover:text-blue-600 font-bold text-sm mx-2">
                  DIFFA TOURS
                </div>
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61554214463692"
                target="_blank"
                className="flex justify-start items-center mx-3 my-2"
              >
                <img src={iconfacebook} className="w-8 h-8" alt="DiffaTours" />
                <div className="hover:text-blue-600 font-bold text-sm mx-2">
                  DiffaTours
                </div>
              </a>
            </div>
          </div>
          {/* images */}
          <div className="flex-1 md:w-1/2 w-screen">
            <div className=" flex flex-col-reverse items-start justify-center  relative ">
              <div className="pt-4 relative w-full  overflow-hidden ">
                <div
                  className="flex items-center overflow-x-scroll"
                  ref={transforGalleryRef}
                >
                  {getImages(transfortImage)?.map((img) => (
                    <div
                      // key={id}
                      style={{
                        backgroundImage: `url(${img})`,
                      }}
                      onClick={() => setActiveImageTrans(img)}
                      className={`${
                        activeImageTrans === img ? "opacity-100" : "opacity-30"
                      } bg-gray-200 w-28 h-16 md:w-40 md:h-24 aspect-video bg-cover bg-center bg-no-repeat rounded-md md:mt-0 mr-2 my-2`}
                    >
                      {/* <img src={img.image} className="object-contain " alt="" /> */}
                    </div>
                  ))}
                </div>
                <button
                  onClick={() => {
                    transforGalleryRef.current.scrollLeft -= 112;
                  }}
                  className="absolute left-0 top-1/2 transform -translate-y-1/2 px-2 py-1 bg-gray-300 text-gray-700 rounded-full"
                >
                  ←
                </button>
                <button
                  onClick={() => {
                    transforGalleryRef.current.scrollLeft += 112;
                  }}
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 px-2 py-1 bg-gray-300 text-gray-700 rounded-full"
                >
                  →
                </button>
              </div>

              <div className="flex-grow w-full rounded-md relative">
                {/* principal image */}
                <img
                  ch="kk"
                  className="rounded-md h-[320px] object-fill mx-auto w-full"
                  src={`${
                    activeImageTrans === ""
                      ? transfortImage[0]
                      : activeImageTrans
                  }`}
                  alt={"diffatours"}
                />
              </div>
            </div>
          </div>
        </div>
        {/* two */}
        <h2 className="font-bold flex mb-4 mt-7">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            data-slot="icon"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5"
            />
          </svg>
          Golf
        </h2>
        <div className="flex flex-col-reverse md:flex-row ">
          {/* Items */}
          <div className="flex-1 md:w-1/2 w-screen my-5">
            <p className="mx-3 my-3">
              {t("While Marrakech is renowned for its breathtaking setting")}
              <br />
              {t(
                "So, to work on your swing on the most beautiful greens in the country"
              )}
            </p>
            <div className="flex flex-col ">
              <a
                href="tel:+212524430418"
                className="mx-3 flex justify-start items-center"
              >
                <img src={iconPhone} className="w-8 h-8" alt="diffatours" />
                <div className="hover:text-blue-600 font-bold text-sm mx-2">
                  (+212) 5-24 43 04 18
                </div>
              </a>
              <a
                href="mailto:contact@difatours.com"
                className="flex justify-start items-center mx-3 my-2"
              >
                <img src={iconMail} className="w-8 h-8" alt="diffatours" />
                <div className="hover:text-blue-600 font-bold text-sm mx-2">
                  contact@difatours.com
                </div>
              </a>
              <a
                href="https://www.instagram.com/diffa_tours_maroc/"
                target="_blank"
                className="flex justify-start items-center mx-3 my-2"
              >
                <img
                  src={iconInstagram}
                  className="w-8 h-8"
                  alt="DIFFA TOURS"
                />
                <div className="hover:text-blue-600 font-bold text-sm mx-2">
                  DIFFA TOURS
                </div>
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61554214463692"
                target="_blank"
                className="flex justify-start items-center mx-3 my-2"
              >
                <img src={iconfacebook} className="w-8 h-8" alt="DiffaTours" />
                <div className="hover:text-blue-600 font-bold text-sm mx-2">
                  DiffaTours
                </div>
              </a>
            </div>
          </div>
          {/* images */}
          <div className="flex-1 md:w-1/2 w-screen">
            <div className=" flex flex-col-reverse items-start justify-center  relative ">
              <div className="pt-4 relative w-full  overflow-hidden ">
                <div
                  className="flex items-center overflow-x-scroll"
                  ref={golfGalleryRef}
                >
                  {getImages(golfImage)?.map((img) => (
                    <div
                      // key={id}
                      style={{
                        backgroundImage: `url(${img})`,
                      }}
                      onClick={() => setActiveImageGolf(img)}
                      className={`${
                        activeImageGolf === img ? "opacity-100" : "opacity-30"
                      } bg-gray-200 w-28 h-16 md:w-40 md:h-24 aspect-video bg-cover bg-center bg-no-repeat rounded-md md:mt-0 mr-2 my-2`}
                    >
                      {/* <img src={img.image} className="object-contain " alt="" /> */}
                    </div>
                  ))}
                </div>
                <button
                  onClick={() => {
                    golfGalleryRef.current.scrollLeft -= 112;
                  }}
                  className="absolute left-0 top-1/2 transform -translate-y-1/2 px-2 py-1 bg-gray-300 text-gray-700 rounded-full"
                >
                  ←
                </button>
                <button
                  onClick={() => {
                    golfGalleryRef.current.scrollLeft += 112;
                  }}
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 px-2 py-1 bg-gray-300 text-gray-700 rounded-full"
                >
                  →
                </button>
              </div>

              <div className="flex-grow w-full rounded-md relative">
                {/* principal image */}
                <img
                  ch="kk"
                  className="rounded-md h-[320px] object-fill mx-auto w-full"
                  src={`${
                    activeImageGolf === "" ? golfImage[0] : activeImageGolf
                  }`}
                  alt={"diffatours"}
                />
              </div>
            </div>
          </div>
        </div>
        {/* three */}
        <h2 className="font-bold flex  mb-4 mt-7">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            data-slot="icon"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5"
            />
          </svg>
          The Congress Palace
        </h2>
        <div className="flex flex-col-reverse md:flex-row-reverse ">
          {/* Items */}
          <div className="flex-1 md:w-1/2 w-screen my-5">
            <p className="mx-3 mt-3 font-bold">{t("Presentation")}</p>
            <p className="mx-3 mb-3">
              {t(
                "Recently renovated and managed by Mövenpick Mansour Eddahbi Marrakech, Le Palais des Congrès has built a solid reputation of excellence over the years. Whatever your technical needs and requirements, our highly experienced team will anticipate all your expectations to ensure the success of your event."
              )}
            </p>
            <p className="mx-3 mt-3  font-bold">{t("Capacity")}</p>
            <p className="mx-3 mb-3">
              {t(
                "A wide range of functional spaces will meet your needs for successful events. These functional spaces can host exhibitions, conferences, or congresses for up to 3000 people."
              )}
            </p>
            <div className="flex flex-col ">
              <a
                href="tel:+212524430418"
                className="mx-3 flex justify-start items-center"
              >
                <img src={iconPhone} className="w-8 h-8" alt="diffatours" />
                <div className="hover:text-blue-600 font-bold text-sm mx-2">
                  (+212) 5-24 43 04 18
                </div>
              </a>
              <a
                href="mailto:contact@difatours.com"
                className="flex justify-start items-center mx-3 my-2"
              >
                <img src={iconMail} className="w-8 h-8" alt="diffatours" />
                <div className="hover:text-blue-600 font-bold text-sm mx-2">
                  contact@difatours.com
                </div>
              </a>
              <a
                href="https://www.instagram.com/diffa_tours_maroc/"
                target="_blank"
                className="flex justify-start items-center mx-3 my-2"
              >
                <img
                  src={iconInstagram}
                  className="w-8 h-8"
                  alt="DIFFA TOURS"
                />
                <div className="hover:text-blue-600 font-bold text-sm mx-2">
                  DIFFA TOURS
                </div>
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61554214463692"
                target="_blank"
                className="flex justify-start items-center mx-3 my-2"
              >
                <img src={iconfacebook} className="w-8 h-8" alt="DiffaTours" />
                <div className="hover:text-blue-600 font-bold text-sm mx-2">
                  DiffaTours
                </div>
              </a>
            </div>
          </div>

          {/* images */}
          <div className="flex-1 md:w-1/2 w-screen">
            <div className=" flex flex-col-reverse items-start justify-center  relative ">
              <div className="pt-4 relative w-full  overflow-hidden ">
                <div
                  className="flex items-center overflow-x-scroll"
                  ref={otherGalleryRef}
                >
                  {getImages(otherImage)?.map((img) => (
                    <div
                      // key={id}
                      style={{
                        backgroundImage: `url(${img})`,
                      }}
                      onClick={() => setActiveImageOther(img)}
                      className={`${
                        activeImageOther === img ? "opacity-100" : "opacity-30"
                      } bg-gray-200 w-28 h-16 md:w-40 md:h-24 aspect-video bg-cover bg-center bg-no-repeat rounded-md md:mt-0 mr-2 my-2`}
                    >
                      {/* <img src={img.image} className="object-contain " alt="" /> */}
                    </div>
                  ))}
                </div>
                <button
                  onClick={() => {
                    otherGalleryRef.current.scrollLeft -= 112;
                  }}
                  className="absolute left-0 top-1/2 transform -translate-y-1/2 px-2 py-1 bg-gray-300 text-gray-700 rounded-full"
                >
                  ←
                </button>
                <button
                  onClick={() => {
                    otherGalleryRef.current.scrollLeft += 112;
                  }}
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 px-2 py-1 bg-gray-300 text-gray-700 rounded-full"
                >
                  →
                </button>
              </div>

              <div className="flex-grow w-full rounded-md relative">
                {/* principal image */}
                <img
                  ch="kk"
                  className="rounded-md h-[320px] object-fill mx-auto w-full"
                  src={`${
                    activeImageOther === "" ? otherImage[0] : activeImageOther
                  }`}
                  alt={"diffatours"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className=" h-40"></div>
      </div>
    </div>
  );
}

export default OtherServiceScreen;
