import React from "react";

function TermsAndConditionsScreen() {
  return (
    <div className="pt-32 lg:px-0 px-4 container max-w-screen-lg mx-auto mt-16">
      <div className="font-bold text-3xl mt-5">Booking Procedure</div>
      <div className="mx-5 my-5">
        <p className="mx-3 my-2">- Choose your suitable category.</p>
        <p className="mx-3 my-2">
          - Once you chose your ticket, click “add to cart”.
        </p>
        <p className="mx-3 my-2">- Click on “checkout”.</p>
        <p className="mx-3 my-2">- Login or Create an account.</p>
        <p className="mx-3 my-2">- Proceed to payment.</p>
        <p className="mx-3 my-2">
          - You will be directed to a secured payment platform “Visa Verified”.
        </p>
        <p className="mx-3 my-2">
          - Fill in your card details and click on Pay.
        </p>
        <p className="mx-3 my-2">
          - Once payment confirmed, click on “Go back to merchant site”.
        </p>
        <p className="mx-3 my-2">
          - You will receive a confirmation e-mail with your booking details.
        </p>
      </div>
      <div className="font-bold text-3xl mt-5 ">Booking Conditions</div>

      <div className="mx-5 my-5">
        <p className="mx-3 my-2">
          - To Confirm your booking, a 100% deposit of your total purchase is
          required.
        </p>
        <p className="mx-3 my-2">- All services are nonrefundable.</p>
      </div>
      <div className="h-40"></div>
    </div>
  );
}

export default TermsAndConditionsScreen;
