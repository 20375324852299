import React from "react";
import generatedHash from "../utils/generateHash";

function PaymentForm({ order, title }) {
  const _url = `http://diffatours.com/order/${order._id}`;
  // test 600004142
  // prod 600003685
  const dataForm = {
    clientid: "600003685",
    storetype: "3d_pay_hosting",
    okUrl: `https://payment.diffatours.com/okfail.php`,
    rnd: "453",
    hashAlgorithm: "ver3",
    encoding: "UTF-8",
    TranType: "PreAuth",
    currency: "504",
    // amount: 3.0,
    amount: order?.totalPrice,
    CallbackResponse: "true",
    BillToCity: "",
    email: order?.shippingAddress?.email,
    tel: order?.shippingAddress?.phone,
    BillToStateProv: "",
    BillToCountry: "",
    BillToStreet1: "",
    BillToTelVoice: "",
    shopurl: `${_url}`,
    failUrl: `https://payment.diffatours.com/okfail.php`,
    callbackUrl: `https://payment.diffatours.com/callback.php`,
    BillToName: `${order?.shippingAddress?.firstName} ${order?.shippingAddress?.lastName}`,
    lang: "fr",
    oid: order._id,
  };
  console.log(dataForm);
  console.log(generatedHash(dataForm));

  return (
    <form method="POST" action="https://payment.cmi.co.ma/fim/est3Dgate">
      {/* <form onSubmit={prePayment}> */}
      {Object.keys(dataForm).map((key) => {
        return (
          <div key={key}>
            <input type={"hidden"} name={key} value={dataForm[key]} />
          </div>
          // <li key={key + index}>{key}:{items[key]}</li>
        );
      })}

      <input type="hidden" name="hash" value={generatedHash(dataForm)} />
      <button className="p-2 bg-red-600 text-white w-full" type="submit">
        {title}
      </button>
    </form>
  );
}

export default PaymentForm;
