import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { currencies, languages } from "../i18n";
import Cookies from "js-cookie";

const Product = ({ product }) => {
  const url = new URLSearchParams(window.location.search);
  const filterDate = url.get("date") || "";
  const currencInfo = localStorage.getItem("Currencies_difatours") || "DHS";

  const { t } = useTranslation();

  const getImageFirst = (images) => {
    console.log("images");
    console.log(images);

    var check = false;
    var image = null;
    if (images.length !== 0) {
      for (let index = 0; index < images?.length; index++) {
        const element = images[index];
        if (element.isfirst) {
          image = element;
          check = true;
        }
      }
    }
    if (!check && images.length !== 0) {
      image = images[0];
    }
    return image;
  };

  return (
    <Link to={`/product/${product._id}/${product.slug}?date=${filterDate}`}>
      <div
        className="relative w-full fade-in lg:group-hover:scale-105 group-hover:shadow-me duration-300 rounded-xl aspect- overflow-hidden   shadow-me"
        css="background: rgb(161, 158, 153);"
      >
        {product.is_promo ? (
          <>
            <div className="absolute top-1 right-2 text-sm bg-red-500 text-white px-5 py-1 rounded">
              - {parseFloat(product.promotion).toFixed(0)}%
            </div>
          </>
        ) : null}
        {process.env.REACT_APP_ENV == "prod" ? (
          <img
            src={`https://api.diffatours.com${
              product &&
              product.images.length !== 0 &&
              getImageFirst(product.images).image
            }`}
            alt={""}
            className=" duration-300 w-full h-52 object-center object-cover  "
          />
        ) : (
          <img
            src={`http://127.0.0.1:8080${
              product && product.images && product.images[0]?.image
            }`}
            alt={product.image}
            className=" duration-300 w-full h-52 object-center object-cover  "
          />
        )}
      </div>

      <div className="p-2">
        <p className="opacity-70  uppercase font-mono tracking-widest text-md ">
          {Cookies.get("i18next") === "ar" && product.namear
            ? product.namear
            : Cookies.get("i18next") === "fr" && product.namefr
            ? product.namefr
            : Cookies.get("i18next") === "it" && product.nameit
            ? product.nameit
            : Cookies.get("i18next") === "nl" && product.namenl
            ? product.namenl
            : product.name}
        </p>
        {product.is_promo ? (
          <>
            <h3 className="font-600   mt-1 text-xl tracking-wider ">
              {currencInfo === "DHS"
                ? product.items?.length === 0
                  ? product.price === 0
                    ? t("free")
                    : `${parseFloat(
                        parseFloat(product.price) -
                          (parseFloat(product.price) * product.promotion) / 100
                      ).toFixed(2)} ` +
                      t(currencies.find((l) => l.code === currencInfo).price)
                  : product.items[0]?.price === 0
                  ? t("free")
                  : `${parseFloat(
                      parseFloat(product.items[0]?.price) -
                        (parseFloat(product.items[0]?.price) *
                          product.promotion) /
                          100
                    ).toFixed(2)} ` +
                    t(currencies.find((l) => l.code === currencInfo).price)
                : product.items?.length === 0
                ? product.price_euro === 0
                  ? t("free")
                  : `${parseFloat(
                      parseFloat(product.price_euro) -
                        (parseFloat(product.price_euro) * product.promotion) /
                          100
                    ).toFixed(2)} ` +
                    t(currencies.find((l) => l.code === currencInfo).price)
                : product.items[0]?.price_euro === 0
                ? t("free")
                : `${parseFloat(
                    parseFloat(product.items[0]?.price_euro) -
                      (parseFloat(product.items[0]?.price_euro) *
                        product.promotion) /
                        100
                  ).toFixed(2)} ` +
                  t(currencies.find((l) => l.code === currencInfo).price)}
            </h3>
          </>
        ) : (
          <h3 className="font-600   mt-1 text-xl tracking-wider ">
            {currencInfo === "DHS"
              ? product.items.length === 0
                ? product.price === 0
                  ? t("free")
                  : `${product.price} ` +
                    t(currencies.find((l) => l.code === currencInfo).price)
                : product.items[0]?.price === 0
                ? t("free")
                : `${product.items[0]?.price} ` +
                  t(currencies.find((l) => l.code === currencInfo).price)
              : product.items?.length === 0
              ? product.price_euro === 0
                ? t("free")
                : `${product.price_euro} ` +
                  t(currencies.find((l) => l.code === currencInfo).price)
              : product.items[0]?.price_euro === 0
              ? t("free")
              : `${product.items[0]?.price_euro} ` +
                t(currencies.find((l) => l.code === currencInfo).price)}
          </h3>
        )}
      </div>

      {/* </div> */}
    </Link>
  );
};

export default Product;
