import React, { useEffect, useRef } from "react";
import { Link, Route, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import SearchBox from './SearchBox'
import { logout } from "../actions/userActions";
import { useState } from "react";
import useOnClickOutside from "../hooks/useOnClickOutside";
import SearchBox from "./SearchBox";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { currencies, languages } from "../i18n";
import { searchProducts } from "../actions/productActions";
import axios from "axios";

if (process.env.REACT_APP_ENV == "prod") {
  axios.defaults.baseURL = "https://api.diffatours.com";
} else {
  axios.defaults.baseURL = "http://127.0.0.1:8080";
  // axios.defaults.baseURL = 'http://127.0.0.1:8000'
}

const Logo = () => {
  return (
    <Link to={"/"}>
      {/* <div className='text-xl text-white font-bold font-mono'>
                Diffa
            </div> */}
      <img
        className=" w-12"
        src="/diffatours-logo-top.png"
        alt="into the wild epic events"
        srcSet="/diffatours-logo-top.png"
      />
    </Link>
  );
};

function Header() {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const [isToggled, setIsToggled] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const [isAdmin, setIsAdmin] = useState(false);

  const [isHeaderSticky, setIsHeaderSticky] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { cartItems } = useSelector((state) => state.cart);
  const logoutHandler = () => {
    dispatch(logout());
  };

  const toggleMenu = () => {
    setIsToggled(!isToggled);
  };
  const handleScroll = () => {
    if (window.pageYOffset > 40) {
      setIsHeaderSticky(true);
    } else {
      setIsHeaderSticky(false);
    }
  };
  const [isFixed, setIsFixed] = useState(true);
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname.includes("category") ||
      location.pathname.includes("about-us")
    ) {
      setIsFixed(false);
    } else {
      setIsFixed(true);
    }
  }, [location]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const refMenu = useRef();

  useOnClickOutside(refMenu, () => setIsToggled(false));

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };

  // search bar
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const currentLang = Cookies.get("i18next") || "en";
  const currencInfo = localStorage.getItem("Currencies_difatours") || "DHS";

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleSearch = async (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    console.log(searchTerm);
    const { data } = await axios.get(
      `/api/products/search?search=${searchTerm}`
    );
    console.log(data["products"]);

    // Perform search logic here and update searchResults state
    const results = data["products"]; // Placeholder for search results
    setSearchResults(results);
  };

  useEffect(() => {}, [i18n]);

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    // localStorage.setItem('language_difatours', lang);
    window.location.reload();
  };

  const handleChangeCurrencies = (currency) => {
    localStorage.setItem("Currencies_difatours", currency);
    window.location.reload();
  };

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
    setSearchResults([]);
    setSearchTerm("");
  };

  const closeModal = () => {
    setShowModal(false);
    setSearchResults([]);
    setSearchTerm("");
  };

  const getImageFirst = (images) => {
    var check = false;
    var image = null;
    for (let index = 0; index < images.length; index++) {
      const element = images[index];
      if (element.isfirst) {
        image = element;
        check = true;
      }
    }
    if (!check) {
      image = images[0];
    }
    return image;
  };

  return location.pathname.split("/")[1] !== "admin" ? (
    <div
      className={` z-50 top-0  fixed w-screen ${
        !isFixed && isHeaderSticky
          ? "bg-white shadow-md "
          : "text-black shadow-md bg-white"
      } ${isFixed ? "bg-white " : ""}  px-8 py-4 justify-between items-center`}
    >
      <div className="Container mx-auto">
        <div className="flex md:mx-16 justify-between">
          {/* curerencys */}
          <div className=" items-center ">
            {pathname.includes("category") ||
            pathname.includes("order") ||
            pathname.includes("placeorder") ||
            pathname.includes("offre") ||
            pathname.includes("product") ||
            pathname.includes("cart") ||
            pathname.includes("shipping") ? (
              <>
                <div className="group relative cursor-pointer">
                  <div className="flex items-center justify-between space-x-2 bg-white px-4">
                    <a class="menu-hover font-bold text-sm text-black uppercase mx-2">
                      {t(currencies.find((l) => l.code === currencInfo).name)}
                    </a>
                    <span className="mx-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="h-5 w-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </span>
                  </div>
                  <div
                    className="invisible w-fit absolute z-50 flex flex-col bg-gray-100 py-1  px-4 text-gray-800 shadow-xl group-hover:visible"
                    onClick=""
                  >
                    {currencies.map((currency) => (
                      <a
                        onClick={(e) => handleChangeCurrencies(currency.code)}
                        className="my-2 w-fit block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2 "
                      >
                        {t(currency.name)}
                      </a>
                    ))}

                    {/* <a onClick={(e) => handleChangeLanguage("fr")} className="my-2 w-fit block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2">French</a>
                                            <a onClick={(e) => handleChangeLanguage("it")} className="my-2 w-fit block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2">Italian</a>
                                            <a onClick={(e) => handleChangeLanguage("ar")} className="my-2 w-fit block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2">Arabic</a> */}
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <div className=" items-center ">
            <div className="group relative cursor-pointer">
              <div className="flex items-center justify-between space-x-2 bg-white px-4">
                <a class="menu-hover font-bold text-sm text-black uppercase mx-3">
                  {t(languages.find((l) => l.code === currentLang).name)}
                  {/* {t('Welcome to React')} */}
                </a>
                <span className="mx-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className=" w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802"
                    />
                  </svg>

                  {/* <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            className="h-6 w-6"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                            />
                                        </svg> */}
                </span>
              </div>
              <div
                className="invisible w-fit absolute z-50 flex flex-col bg-gray-100 py-1  px-4 text-gray-800 shadow-xl group-hover:visible"
                onClick=""
              >
                {languages.map((lang) => (
                  <a
                    onClick={(e) => handleChangeLanguage(lang.code)}
                    className="my-2 w-fit block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2 "
                  >
                    {t(lang.name)}
                  </a>
                ))}

                {/* <a onClick={(e) => handleChangeLanguage("fr")} className="my-2 w-fit block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2">French</a>
                                    <a onClick={(e) => handleChangeLanguage("it")} className="my-2 w-fit block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2">Italian</a>
                                    <a onClick={(e) => handleChangeLanguage("ar")} className="my-2 w-fit block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2">Arabic</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Container mx-auto my-1">
        <hr />
      </div>

      <div className="container mx-auto flex items-center justify-between ">
        <div className=" flex  items-center">
          {/* menu mobile */}

          <div className={` ${openMenu ? "" : "hidden"}`}>
            <div
              onClick={() => setOpenMenu(false)}
              className="z-30 absolute w-screen h-screen bg-black bg-opacity-40 left-0 top-0"
            ></div>

            <div
              className={` md:hidden h-full  px-8 py-4 border shadow-md fixed bottom-0 z-50 w-1/2 left-0 p-3 bg-white`}
            >
              <div className="flex items-center">
                {}
                <div onClick={handleOpenMenu} className=" ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h8m-8 6h16"
                    />
                  </svg>
                </div>
                <Logo />
              </div>

              <div className="flex flex-col gap-y-6 pt-10">
                <a
                  href="/"
                  className={`px-3 ${
                    location.hash === "" && location.pathname === "/"
                      ? "text-primary"
                      : "text-black"
                  }`}
                >
                  {t("home_page")}
                </a>

                <a
                  href="/#services"
                  className={`px-3 ${
                    location.hash === "#services"
                      ? "text-primary"
                      : "text-black"
                  }`}
                >
                  {t("our_services")}
                </a>

                <a
                  href="/our-excursions"
                  className={`px-3 ${
                    location.pathname === "/our-excursions"
                      ? "text-primary"
                      : "text-black"
                  }`}
                >
                  {t("our_excursions")}
                </a>
                <a
                  href="/our-promotions"
                  className={`px-3 ${
                    location.pathname === "/our-promotions"
                      ? "text-primary"
                      : "text-black"
                  }`}
                >
                  {t("our_promotions")}
                </a>
                <a
                  href="/about-us"
                  className={`px-3 ${
                    location.pathname === "/about-us"
                      ? "text-primary"
                      : "text-black"
                  }`}
                >
                  {t("who_are_we")}
                </a>
                <a
                  href="/other-services"
                  className={`px-3 ${
                    location.pathname === "/other-services"
                      ? "text-primary"
                      : "text-black"
                  }`}
                >
                  {t("Other Services")}
                </a>
                {userInfo ? (
                  userInfo.isAdmin ? (
                    <a
                      href="/admin"
                      className={`px-3  ${
                        location.pathname === "/admin"
                          ? "text-primary"
                          : "text-black"
                      }`}
                    >
                      {t("my_profile")}
                    </a>
                  ) : (
                    <a
                      href="/profile"
                      className={`px-3  ${
                        location.pathname === "/profile"
                          ? "text-primary"
                          : "text-black"
                      }`}
                    >
                      {t("my_profile")}
                    </a>
                  )
                ) : (
                  <a
                    href="/login"
                    className={`px-3 ${
                      location.pathname === "/login"
                        ? "text-primary"
                        : "text-black"
                    }`}
                  >
                    {t("sign_in")}
                  </a>
                )}
                {userInfo ? (
                  <a
                    onClick={logoutHandler}
                    href="#!"
                    className={`text-sm font-bold px-3 ${
                      location.pathname === "/login"
                        ? "text-primary"
                        : "text-black"
                    }`}
                  >
                    {t("sign_out")}
                  </a>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          <div onClick={handleOpenMenu} className="block md:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </div>
          <Logo />
        </div>

        <div className="md:flex items-center text-black text-xl  hidden">
          <a
            href="/"
            className={`text-sm font-bold px-3 ${
              location.hash === "" && location.pathname === "/"
                ? "text-primary"
                : "text-black"
            }`}
          >
            {t("home_page")}
          </a>

          <a
            href="/#services"
            className={`text-sm font-bold px-3 ${
              location.hash === "#services" ? "text-primary" : "text-black"
            }`}
          >
            {t("our_services")}
          </a>

          <a
            href="/our-excursions"
            className={`text-sm font-bold px-3 ${
              location.pathname === "/our-excursions"
                ? "text-primary"
                : "text-black"
            }`}
          >
            {t("our_excursions")}
          </a>
          <a
            href="/our-promotions"
            className={`text-sm font-bold px-3 ${
              location.pathname === "/our-promotions"
                ? "text-primary"
                : "text-black"
            }`}
          >
            {t("our_promotions")}
          </a>

          <a
            href="/about-us"
            className={`text-sm font-bold px-3 ${
              location.pathname === "/about-us" ? "text-primary" : "text-black"
            }`}
          >
            {t("who_are_we")}
          </a>
          <a
            href="/other-services"
            className={`text-sm font-bold px-3 ${
              location.pathname === "/other-services"
                ? "text-primary"
                : "text-black"
            }`}
          >
            {t("Other Services")}
          </a>

          {userInfo ? (
            userInfo.isAdmin ? (
              <a
                href="/admin"
                className={`text-sm font-bold px-3  ${
                  location.pathname === "/admin" ? "text-primary" : "text-black"
                }`}
              >
                {t("my_profile")}
              </a>
            ) : (
              <a
                href="/profile"
                className={`text-sm font-bold px-3  ${
                  location.pathname === "/profile"
                    ? "text-primary"
                    : "text-black"
                }`}
              >
                {t("my_profile")}
              </a>
            )
          ) : (
            <a
              href="/login"
              className={`text-sm font-bold px-3 ${
                location.pathname === "/login" ? "text-primary" : "text-black"
              }`}
            >
              {t("sign_in")}
            </a>
          )}
          {userInfo ? (
            <a
              onClick={logoutHandler}
              href="#!"
              className={`text-sm font-bold px-3 ${
                location.pathname === "/login" ? "text-primary" : "text-black"
              }`}
            >
              {t("sign_out")}
            </a>
          ) : (
            <></>
          )}

          {/* {userInfo ?
                        <div ref={refMenu} className='relative cursor-pointer select-none' onClick={toggleMenu}>
                             <a href="#!" className={`text-sm font-bold px-3  ${location.pathname === "/profile" ? "text-primary" : "text-black"}`}>
                                {t("my_profile")}
                            </a> 
                            {userInfo && (
                                <div className={`${isToggled ? '' : "hidden"} absolute bg-gray-100 text-secondary px-4 rounded-md p-2   right-0 z-50 `}>

                                    <div className="  text-xl whitespace-nowrap p-2 w-24">
                                        <div className="" >
                                            <Link to="/profile" className="pr-3">
                                                My profile
                                            </Link>
                                        </div>
                                        {userInfo.isAdmin && (
                                            <div>
                                                <Link to='/admin/'>
                                                    <div>Admin</div>
                                                </Link>

                                            </div>
                                        )
                                        }
                                        <div>
                                            <button onClick={logoutHandler}>
                                                Logout
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div> :
                        <a href="/login" className={`text-sm font-bold px-3 ${location.pathname === "/login" ? "text-primary" : "text-black"}`}>
                            {t("sign_in")}
                        </a>
                    } */}
          {/* <a href="/cart" className={`text-sm font-bold px-3 ${location.pathname === "/cart" ? "text-primary" : "text-black"}`}>
                        Panier
                    </a> */}
        </div>

        <div className="flex items-center ">
          {/* <a href="/#packs" className='py-1 px-4 bg-primary rounded-md text-xl '>
                        Our Products
                    </a> */}

          <div className="pl-3">
            <button className="flex" onClick={openModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                stroke="currentColor"
                viewBox="0 0 50 50"
                width="50px"
                height="50px"
              >
                <path d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z" />
              </svg>
            </button>
            {/* <Route render={({ history }) => <SearchBox history={history} />} /> */}
            {/* {
                            isPopupOpen ? <Route render={({ history }) => <SearchBox history={history} />} /> :

                                <button className="flex" onClick={togglePopup}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className='h-6 w-6' stroke="currentColor" viewBox="0 0 50 50" width="50px" height="50px"><path d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z" /></svg>
                                </button>
                        } */}
          </div>

          <div className="pl-3">
            <Link className="flex" to="/cart">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
              <div>
                {cartItems && cartItems.length && <i>{cartItems.length}</i>}
              </div>
            </Link>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50">
          <div className="bg-white px-3 py-2 rounded shadow-md md:w-1/3 w-11/12 ">
            <button
              className="mb-4 text-gray-600 hover:text-gray-800"
              onClick={closeModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <input
              type="text"
              placeholder={t("find_products")}
              value={searchTerm}
              onChange={handleSearch}
              className="border rounded px-2 py-1 w-full"
            />
            <ul
              className="mt-4 scroll-m-2  max-h-[80vh] overflow-y-auto"
              dir="ltr"
            >
              {searchResults?.map((product, index) => (
                <li key={index} className="py-1">
                  <a href={`/product/${product._id}/${product.slug}`}>
                    <div className="flex ">
                      <img
                        src={`${
                          process.env.REACT_APP_ENV === "prod"
                            ? "https://api.diffatours.com"
                            : "http://127.0.0.1:8080"
                        }${
                          product &&
                          product.images &&
                          getImageFirst(product.images)?.image
                        }`}
                        alt={product.name}
                        className="w-20 h-20"
                      />
                      <div className="pl-4">
                        <div className="text-md ">{product.name}</div>
                        <div className="text- opacity-90 ">
                          {currencInfo === "DHS"
                            ? product.price
                            : product.price_euro}{" "}
                          {t(
                            currencies.find((l) => l.code === currencInfo).price
                          )}
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div></div>
  );
}

export default Header;
