import React from "react";
import Meta from "../components/Meta";
import { useTranslation } from "react-i18next";

function FailPayment() {
  const { t } = useTranslation();
  return (
    <div className="pt-32  min-h-screen w-full flex flex-col justify-between items-center ">
      <Meta title={"Fail payment"} />
      <div className="pt-20 flex flex-col items-center text-center">
        <div className="text-red-600 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-20 w-20"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        </div>
        <div>
          <h1 className="text-2xl font-bold py-4 text-red-600">
            {t("Payment failed")}{" "}
          </h1>
          <h3 className="text-xl font-semibold"> {t("Please try again")}</h3>
        </div>
      </div>
    </div>
  );
}

export default FailPayment;
