import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { listProductsPromotion } from '../actions/productActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Paginate from '../components/Paginate'
import Product from '../components/Product'
import Calendar from 'react-calendar'
import { Range } from 'react-range'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ar from 'date-fns/locale/ar'
import it from 'date-fns/locale/it'
import fr from 'date-fns/locale/fr'
import nl from 'date-fns/locale/nl'
import en from 'date-fns/locale/en-US'
import { currencies } from '../i18n'

const Header = () => {
    return (
        <div className='bg-secondary bg-opacity-25 rounded-b-3xl flex flex-col justify-end pb-10 items-center h-32 w-full'>
            {/* <h1 className='text-3xl md:text-4xl text-center max-w-xl '>
          On voyage pour changer, non de lieu, mais d'idées
        </h1> */}
        </div>
    )
}

function OffreScreen({ match, history }) {
    const { t } = useTranslation();

    const dispatch = useDispatch()
    const location = useLocation()

    const [dateFilter, setDateFilter] = useState(new Date());
    const [dtFilter, setDtFilter] = useState(new Date());
    const [rangeValues, setRangeValues] = useState([0, 100]);
    const [rangeValueMax, setRangeValueMax] = useState(100);
    const [rangeValueMin, setRangeValueMin] = useState(0);

    const url = new URLSearchParams(window.location.search)

    const pageNumber = match.params.pageNumber || 1
    const filterDate = url.get('date') || ''
    const nPage = url.get('page') || 1
    const keyword = url.get('keyword') || ''
    const max = url.get('max') || ''
    const min = url.get('min') || ''



    // const keyword = match.params.keyword || ''
    // const keyword = location.search ? location.search.split("?")[1].split("=")[0] : ""
    // console.log(keyword)
    const category = match.params.category || ''

    const type = match.params.type || ''

    const { loading, error, products, page, pages } = useSelector((state) => state.productListPromotion)

    const [rangeValue, setRangeValue] = useState(0);
    const [maxValue, setMaxValue] = useState(100);

    const handleRangeChange = (values) => {
        console.log(values);
        setRangeValues(values);
        setRangeValueMin(values[0])
        setRangeValueMax(values[1])
    };



    const hundlChangeFilter = (e) => {
        setDateFilter(e);
        const currentDate = e;
        const numberOfDaysToAdd = 1; // Change this value to the number of days you want to add

        const futureDate = currentDate;
        futureDate.setDate(futureDate.getDate() + numberOfDaysToAdd);

        console.log(futureDate.toISOString());

        console.log("change + " + e.toISOString().split('T')[0]);


        // setDtFilter(e.toISOString().split('T')[0]);
        dispatch(listProductsPromotion(keyword, pageNumber, futureDate.toISOString().split('T')[0], max, min))
        history.push(`?date=${e.toISOString().split('T')[0]}&max=${max}&min=${min}&page=1&keyword=${keyword}`)
    }

    const hundlChangeInputFilter = (e) => {
        // setDateFilter(e);
        setDtFilter(e.toISOString().split('T')[0]);
        dispatch(listProductsPromotion(keyword, pageNumber, e.toISOString().split('T')[0], max, min))
        history.push(`?date=${e.toISOString().split('T')[0]}&max=${max}&min=${min}&page=1&keyword=${keyword}`)
    }



    useEffect(() => {
        dispatch(listProductsPromotion(keyword, nPage, filterDate, max, min, currencInfo))


    }, [dispatch, pageNumber, keyword])

    const currencInfo = localStorage.getItem("Currencies_difatours") || 'DHS';

    const getMaxPrice = (products) => {
        var maxPrice = 100;
        if (currencInfo === "DHS") {
            for (let index = 0; index < products.length; index++) {
                const element = products[index];
                if (parseFloat(element.price) >= maxPrice) {
                    maxPrice = parseFloat(element.price);
                }
            }
        } else {
            for (let index = 0; index < products.length; index++) {
                const element = products[index];
                if (parseFloat(element.price_euro) >= maxPrice) {
                    maxPrice = parseFloat(element.price_euro);
                }
            }
        }
        return maxPrice + 100
    }

    useEffect(() => {
        var maxPrice = 100;
        if (currencInfo === "DHS") {


            for (let index = 0; index < products.length; index++) {
                const element = products[index];
                if (parseFloat(element.price) >= maxPrice) {
                    maxPrice = parseFloat(element.price);
                }
            }
        } else {
            for (let index = 0; index < products.length; index++) {
                const element = products[index];
                if (parseFloat(element.price_euro) >= maxPrice) {
                    maxPrice = parseFloat(element.price_euro);
                }
            }
        }
        setRangeValues([0, maxPrice + 100]);
        setRangeValueMin(0);
        setRangeValueMax(maxPrice + 100);
    }, [products])




    return (
        <div className=" min-h-screen ">

            <Header />

            <div className='pb-20 xl:px-3 px-8 max-w-screen-xl mx-auto'>

                <div className=''>


                </div>

                {loading ? (
                    <Loader />
                ) : error ? (
                    <Message variant='danger'>{t(error)}</Message>
                ) : (
                    <>
                        <div className="w-64 py-4 flex  items-center pb-8">

                        </div>
                        <div className=' md:flex'>
                            {/* sidebar filter */}
                            <div className=' md:w-1/4 md:min-h-screen rounded px-3 shadow md:mx-1 pt-2 mt-2 pb-2'>
                                <div className='mb-3'>
                                    <a href='?' className='text-gray-600 font-bold' >{t("clean_filter")}</a>
                                </div>
                                <div>
                                    <div className='md:hidden block w-full'>
                                        <div className='p-1 w-full ' >
                                            <DatePicker

                                                className='m-1 mt-2 w-full p-2 px-6 border rounded-full'
                                                selected={dateFilter}
                                                onChange={(date) => hundlChangeInputFilter(date)}
                                                dateFormat="dd/MM/yyyy" // Customize the date format as needed
                                                locale={Cookies.get("i18next") === "ar" ? ar : Cookies.get("i18next") === "fr" ? fr : Cookies.get("i18next") === "it" ? it: Cookies.get("i18next") === "nl" ? nl : en} // Set the Arabic locale
                                            />
                                            {/* <input lang="ar" dir="rtl" value={dateFilter} onChange={(e) => hundlChangeInputFilter(e.target.value)} className='m-1 mt-2 w-full p-2 pl-6  border-none outline-none rounded-full' type='date' /> */}
                                        </div>
                                    </div>
                                    <div className='md:block hidden'>
                                        <Calendar locale={Cookies.get("i18next") || 'en'} className=' mx-auto w-full' value={dateFilter - 1} onChange={hundlChangeFilter} />
                                    </div>
                                </div>
                                <div className='mx-3'>
                                    <div className='flex mt-3'>
                                        <div className='flex-1'>{t("price_dhs", { currency: t(currencies.find(l => l.code === currencInfo).price) })}</div>
                                        <div className='text-red-600'>
                                            <a href={`?date=${filterDate}&max=${rangeValueMax}&min=${rangeValueMin}&keyword=${keyword}&page=1`}> {t("ok")}</a>
                                        </div>
                                    </div>
                                    <div className='mt-6' dir='ltr'>
                                        <Range
                                            step={1}
                                            min={0}
                                            max={getMaxPrice(products)}
                                            values={rangeValues}
                                            onChange={handleRangeChange}
                                            renderTrack={({ props, children }) => (
                                                <div
                                                    {...props}
                                                    style={{
                                                        ...props.style,
                                                        height: '6px',
                                                        background: '#ccc',
                                                    }}
                                                >
                                                    {children}
                                                </div>
                                            )}
                                            renderThumb={({ props }) => (
                                                <div
                                                    {...props}
                                                    style={{
                                                        ...props.style,
                                                        height: '20px',
                                                        width: '20px',
                                                        borderRadius: '50%',
                                                        background: '#8fce00',
                                                        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className='flex mt-5' dir='ltr'>
                                        <input type='text' className='w-full text-black border rounded px-2 text-center py-1 font-bold' value={rangeValueMin} disabled />
                                        <span className='text-lg mx-3'> - </span>
                                        <input type='text' className='w-full text-black border rounded px-2 text-center py-1 font-bold' value={rangeValueMax} disabled />
                                    </div>
                                </div>
                            </div>
                            {/* end sidebar filter */}
                            <div className='flex-1 shadow rounded  md:min-h-screen px-3 md:mx-1 pt-2 mt-2 pb-2'>
                                {products.length > 0 ?
                                    <div className="grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 grid-cols-1 lg:gap-x-6 gap-x-4 lg:gap-y-2 gap-y-1  lg:col-span-3">
                                        {products.map((product) => (
                                            <div className="group cursor-pointer fade-in text-sm lg:-20 duration-300" key={product._id} dir='ltr' >
                                                <Product product={product} />
                                            </div>
                                        ))}
                                    </div>
                                    :
                                    <div className='flex flex-col items-center justify-center  h-64 '>
                                        <img src="/icons/icon-camping.svg" alt="" srcset="" />
                                        <div>
                                            {t("search_no_results")}
                                        </div>
                                    </div>
                                }

                                <Paginate
                                    pages={pages}
                                    page={nPage}
                                    keyword={keyword}
                                    type={type}
                                    max={max}
                                    min={min}
                                    date={filterDate}
                                />



                                <div>
                                    {/* <h1>Here put some content</h1> */}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default OffreScreen
