import React, { memo, useEffect, useRef, useState } from 'react'
import Layout from './Layout'
import { withGoogleMap, GoogleMap, Marker, } from 'react-google-maps'
import { useDispatch, useSelector } from 'react-redux'
import { addPickup, deleteOrder, deletePickup, listPickups } from '../../actions/orderActions'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import useOnClickOutside from '../../hooks/useOnClickOutside'

const PickupScreen = ({ history, match }) => {
    const dispatch = useDispatch()
    const [address, setAddress] = useState("")
    const [locationx, setLocationx] = useState("")
    const [locationy, setLocationy] = useState("")
    const [zoom, setZoom] = useState(8)
    const [zoomSave, setZoomSave] = useState(8)
    // 
    const [defaultx, setDefaultx] = useState("31.6347412")
    const [defaulty, setDefaulty] = useState("-8.0902547")



    const { loadingPick, errorPick, pickups } = useSelector((state) => state.pickupList)

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    function handleZoomChanged() {
        // console.log(mapRef.current.getZoom());
        if (mapRef.current) {
            setZoomSave(mapRef.current.getZoom());
        }
    }
    const mapRef = useRef(null);
    const MyMapComponent = withGoogleMap(props => (
        <GoogleMap
            onClick={handleMapClick}
            ref={mapRef}
            center={{ lat: parseFloat(defaultx), lng: parseFloat(defaulty) }}
            defaultZoom={zoom}
        // onZoomChanged={handleZoomChanged}
        >
            {
                locationx && locationy ?
                    <Marker position={{ lat: parseFloat(locationx), lng: parseFloat(locationy) }} />
                    : null
            }

        </GoogleMap>
    ));


    useEffect(() => {

        if (userInfo && userInfo.isAdmin) {
            dispatch(listPickups())
        } else {
            history.push('/login')
        }
    }, [ history, userInfo])






    const handleMapClick = (event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        console.log({ lat, lng });
        setDefaultx(lat);
        setDefaulty(lng)
        setZoom(zoomSave)
        setLocationx(lat)
        setLocationy(lng)
    };

    const deletePickupfunction = async (idPick) => {
        await dispatch(deletePickup(idPick)).then(() => { });
        await dispatch(listPickups()).then(() => { });
    }


    const submitHandler = async (e) => {
        e.preventDefault()
        await dispatch(addPickup(address, locationx, locationy)).then(() => {
            setAddress("");
            setLocationx("");
            setLocationy("");
        });
        await dispatch(listPickups()).then(() => { });
    }


    return (
        <Layout>
            <div className="px-2  w-full">
                <div className='pb-4 flex items-center justify-between'>
                    <div className='text-xl font-bold'>
                        Points de ramassage
                    </div>
                </div>

                <>
                    {/* add  */}
                    <form className='relative' onSubmit={submitHandler}>
                        <div className='md:flex items-center'>
                            <div className='md:w-1/2'>

                                <div className='mx-2'>
                                    <label for="name">Nom du point</label>
                                    <input required id="name" placeholder='Sidi youssef ben ali, Marrakech' className='p-2 border outline-none bg-transparent w-full' type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
                                </div>
                                <div className='md:flex  md:mx-0 mx-2 mt-2'>
                                    <div className='md:w-1/2 w-full md:mx-2'>
                                        <label for="location_x">Latitude</label>
                                        <input required id="location_x" placeholder='31.5893157' className='p-2 border outline-none bg-transparent w-full' type="number" step={0.01} value={locationx} onChange={(e) => {
                                            setLocationx(e.target.value);
                                            setDefaultx(e.target.value);
                                        }} />
                                    </div>
                                    <div className='md:w-1/2 w-full md:mx-2'>
                                        <label for="location_y">Longitude</label>
                                        <input required id="location_y" placeholder='-7.9908434' className='p-2 border outline-none bg-transparent w-full' type="number" step={0.01} value={locationy} onChange={(e) => {
                                            setLocationy(e.target.value);
                                            setDefaulty(e.target.value);
                                        }} />
                                    </div>
                                </div>

                            </div>

                            <div className='w-full md:w-1/2 mt-2 h-52 mx-2 border rounded '>
                                <MyMapComponent
                                    containerElement={<div style={{ height: '100%' }} />}
                                    mapElement={<div style={{ height: '100%' }} />}
                                />
                            </div>

                        </div>
                        <div className=' mt-5 mx-2 items-center justify-center'>
                            <button type='submit' className='rounded-full bg-blue-600 md:w-1/3 w-full text-white font-bold px-3 py-2' >Ajouter le point</button>
                        </div>
                    </form>

                    <hr className='my-4' />
                    {/*  */}
                    <div >
                        <div class="flex flex-col bg-white min-w-full  rounded-md mt-4 ">
                            <div class=" sm:-mx-6 lg:-mx-8 ">
                                <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                    <div class="overflow-hiddens">
                                        <table class="min-w-full ">
                                            <thead class="bg-white border-b">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                                    >
                                                        #
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                                    >
                                                        Nom du point
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                                    >
                                                        Coordonnées
                                                    </th>

                                                    <th
                                                        scope="col"
                                                        class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                                    ></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loadingPick ? (
                                                    <Loader />
                                                ) : errorPick ? (
                                                    <Message variant="danger">{errorPick}</Message>
                                                ) : (
                                                    <>
                                                        {pickups.map((pickup, id) => (
                                                            <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                                                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                                    <p className="text-blue-600" >
                                                                        {pickup.id}
                                                                    </p>
                                                                </td>
                                                                <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                                    {pickup.name}
                                                                </td>
                                                                <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                                    {pickup.location_x} , {pickup.location_y}
                                                                </td>



                                                                <td class="text-sm relative text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                                    {/* °°° */}
                                                                    <div className='cursor-pointer relative'>
                                                                        <button onClick={() => deletePickupfunction(pickup.id)}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                                            </svg>
                                                                        </button>

                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                )}


                                                <tr className="h-16"></tr>
                                            </tbody>
                                        </table>
                                        {/* <Paginate section={"orderlist"} pages={pages} page={page} /> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </>


            </div>
        </Layout>
    )
}

export default PickupScreen



