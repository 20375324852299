import pack from "./pack";
import SHA512 from "./SHA512";

const generatedHash = (formData) => {
  let hashval = "";
  let arr = [];

  Object.keys(formData).map((key) => {
    arr.push(key);
  });

  const arr2 = arr.sort(function (a, b) {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  });

  arr2.forEach(function (element) {
    if (element != "hash" && element != "encoding") {
      let escapedParamValue = String(formData[element])
        .replace("|", "\\|")
        .replace("\\", "\\\\");
      hashval = hashval + escapedParamValue + "|";
    }
  });

  const storeKey = "Diffatours_2024";
  hashval = hashval + storeKey;

  const calculatedHashValue = SHA512(hashval);
  const hash = btoa(pack("H*", calculatedHashValue));

  return hash;
};

export default generatedHash;
